import React, { useEffect, useState } from "react";
import { useAppContext } from "../../AppContext";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { getDateRanges, getStatistics, getUsers } from "../../app.service";
import { notificationType } from "../notification.type";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { formatNumberWithThousandsSeparator } from "../methods";

interface UserActivity {
  calls: number | string;
  emails: number | string;
  meetings: number | string;
}

interface UserOpportunity {
  amountOpenPipeline: number;
  amountClosedWon: number;
  winRate: number | null;
}

interface User {
  userId: number;
  userName: string;
  userRole: string;
}

const Home: React.FC = () => {
  const { currentUser, currentToken, currentCustomer } = useAppContext();
  const [loading, setLoading] = useState<boolean>(true);
  const [periodFilter, setPeriodFilter] = useState<string>("thisQuarter");
  const [dateRanges, setDateRanges] = useState<
    { dateRange: string; label: string; dateKey: string }[]
  >([]);
  const [timeZone, setTimeZone] = useState<string>("UTC");
  const [statistics, setStatistics] = useState<any>(null);
  const [userActivity, setUserActivity] = useState<UserActivity>({
    calls: "N/A",
    emails: "N/A",
    meetings: "N/A",
  });

  const [userOpportunities, setUserOpportunities] = useState<UserOpportunity>({
    amountOpenPipeline: 0,
    amountClosedWon: 0,
    winRate: null,
  });

  const [activitiesSeries, setActivitiesSeries] = useState<any[]>([]);
  const [activitiesCategories, setActivitiesCategories] = useState<any[]>([]);

  const [opportunitySeries, setOpportunitySeries] = useState<any[]>([]);
  const [opportunityCategories, setOpportunityCategories] = useState<any[]>([]);

  const [dateRangesFetched, setDateRangesFetched] = useState<boolean>(false);
  const [statisticsFetched, setStatisticsFetched] = useState<boolean>(false);

  const [teamSummary, setTeamSummary] = useState<any>(null);
  const [teamTable, setTeamTable] = useState<any[]>([]);

  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setTimeZone(browserTimeZone);
  }, []);

  useEffect(() => {
    if (currentToken) {
      fetchData();
    }
  }, [currentToken]);

  const fetchData = async () => {
    if (currentToken) {
      const dateRangeResult = await getDateRanges(currentToken);
      if (dateRangeResult.success) {
        setDateRanges(dateRangeResult.data);
        setDateRangesFetched(true);
      }

      const usersResult = await getUsers(currentToken);
      if (usersResult.success) {
        setUsers(usersResult.data);
      }
    }
  };

  useEffect(() => {
    if (periodFilter && currentToken) {
      fetchStatistics(periodFilter, currentToken);
    }
  }, [periodFilter, currentToken]);

  useEffect(() => {
    if (statisticsFetched && dateRangesFetched) {
      setLoading(false);
    }
  }, [statisticsFetched, dateRangesFetched]);

  const fetchStatistics = async (
    periodFilter: string,
    currentToken: string
  ) => {
    const statisticsResult = await getStatistics(
      currentToken,
      periodFilter,
      timeZone
    );

    if (statisticsResult.success) {
      setStatistics(statisticsResult.data);
      setStatisticsFetched(true);
    } else {
      notificationType.error("Error!", statisticsResult.message);
    }
  };

  useEffect(() => {
    // Ensure statistics and dateRanges are available
    if (statistics && dateRanges.length > 0) {
      const currentUserActivity = statistics.activity?.aggregates.find(
        (item: { userId: any }) => item.userId === currentUser.userId
      );

      // Set user activity or default values
      if (currentUserActivity) {
        setUserActivity(currentUserActivity);
      } else {
        setUserActivity({
          calls: "N/A",
          emails: "N/A",
          meetings: "N/A",
        });
      }

      // Filter activity data for the current user
      const filteredActivityData = statistics.activity?.data?.filter(
        (item: { userId: any }) => item.userId === currentUser.userId
      );

      // Ensure dateKey is defined and find it
      const dateKey =
        dateRanges.find((item) => item.dateRange === periodFilter)?.dateKey ||
        "date";

      // Check if dateKey is defined before using it
      const currentActivityCategories = dateKey
        ? (Array.from(
            new Set(
              statistics.activity.data?.map(
                (item: { [x: string]: any }) => item[dateKey]
              )
            )
          ) as string[]) // Assert as string array
        : []; // Default to empty array if dateKey is undefined

      // Sort activity categories
      currentActivityCategories.sort((a: string, b: string) => {
        const dateA = new Date(a);
        const dateB = new Date(b);
        return dateA.getTime() - dateB.getTime(); // Compare timestamps
      });

      // Prepare activities series
      const currentActivitySeries = [
        {
          name: "Calls",
          data:
            filteredActivityData?.map((item: { calls: any }) => item.calls) ||
            [], // Default to empty array
        },
        {
          name: "Emails",
          data:
            filteredActivityData?.map((item: { emails: any }) => item.emails) ||
            [], // Default to empty array
        },
        {
          name: "Meetings",
          data:
            filteredActivityData?.map(
              (item: { meetings: any }) => item.meetings
            ) || [], // Default to empty array
        },
      ];

      // Set activities states
      setActivitiesCategories(currentActivityCategories);
      setActivitiesSeries(currentActivitySeries);
      // Opportunity Data Handling
      const currentUserOpportunities =
        statistics.opportunities?.aggregates.find(
          (item: { userId: any }) => item.userId === currentUser.userId
        );

      // Set user opportunities or default values
      if (currentUserOpportunities) {
        setUserOpportunities(currentUserOpportunities);
      } else {
        setUserOpportunities({
          amountOpenPipeline: 0,
          amountClosedWon: 0,
          winRate: null,
        });
      }

      const filteredOpportunityData = statistics.opportunities?.data?.filter(
        (item: { userId: any }) => item.userId === currentUser.userId
      );

      // Prepare opportunity categories and ensure they are sorted
      const opportunityCategories =
        (filteredOpportunityData
          ?.map((item: { [key: string]: any }) => item[dateKey]) // Use dateKey instead of hardcoded string
          .filter(
            (value: any, index: any, self: string | any[]) =>
              self.indexOf(value) === index
          ) as string[]) || []; // Assert as string array

      // Sort opportunity categories based on the selected dateKey format
      opportunityCategories.sort((a: string, b: string) => {
        const dateA = new Date(a);
        const dateB = new Date(b);
        return dateA.getTime() - dateB.getTime(); // Compare timestamps
      });

      // Prepare opportunities series for the chart
      const opportunitySeries = [
        {
          name: "Win Rate",
          type: "line", // Line series for win rate
          data: opportunityCategories.map((date: string) => {
            const dataPoint = filteredOpportunityData.find(
              (item: { [key: string]: any }) => item[dateKey] === date
            );
            return dataPoint ? dataPoint.winRate * 100 : null; // Use null if no win rate data
          }),
        },
        {
          name: "Pipeline Generation",
          type: "area", // Stacked area series for pipeline
          data: opportunityCategories.map((date: string) => {
            const dataPoint = filteredOpportunityData.find(
              (item: { [key: string]: any }) => item[dateKey] === date
            );
            return dataPoint ? dataPoint.amountPipelineGeneration : 0; // Default to 0 if no data
          }),
        },
        {
          name: "Closed Won",
          type: "area", // Stacked area series for closed won
          data: opportunityCategories.map((date: string) => {
            const dataPoint = filteredOpportunityData.find(
              (item: { [key: string]: any }) => item[dateKey] === date
            );
            return dataPoint ? dataPoint.amountClosedWon : 0; // Default to 0 if no data
          }),
        },
      ];

      // Set opportunities states
      setOpportunityCategories(opportunityCategories); // Assuming you have a state for categories
      setOpportunitySeries(opportunitySeries); // Assuming you have a state for series
    }
  }, [statistics, dateRanges, currentUser]);

  const activityOptions: ApexOptions = {
    chart: {
      type: "area", // Make sure this is typed correctly
      //height: 800,
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    colors: ["#008FFB", "#00E396", "#CED4DC"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "monotoneCubic",
    },
    fill: {
      type: "gradient",
      gradient: {
        opacityFrom: 0.6,
        opacityTo: 0.8,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
    },
    xaxis: {
      type: "category",
      categories: activitiesCategories,
    },
  };

  const opportunityOptions: ApexOptions = {
    chart: {
      type: "area",
      //height: 800,
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    colors: ["#008FFB", "#00E396", "#CED4DC"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "monotoneCubic",
    },
    fill: {
      type: "gradient",
      gradient: {
        opacityFrom: 0.6,
        opacityTo: 0.8,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
    },
    xaxis: {
      type: "category",
      categories: opportunityCategories,
    },
    yaxis: [
      {
        title: {
          text: "Win Rate",
        },
        labels: {
          formatter: (value) =>
            formatNumberWithThousandsSeparator(value, "%", 0, true),
        },
        min: 0,
        max: 100,
      },
      {
        opposite: true,
        title: {
          text: "ARR",
        },
        labels: {
          formatter: (value) =>
            formatNumberWithThousandsSeparator(value, "SEK"),
        },
      },
    ],
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y, { seriesIndex }) => {
          if (y !== undefined && y !== null) {
            if (seriesIndex === 0) {
              return formatNumberWithThousandsSeparator(y, "%", 0, true);
            } else {
              return formatNumberWithThousandsSeparator(y, "SEK");
            }
          }
          return y;
        },
      },
    },
  };

  useEffect(() => {
    // Ensure statistics and dateRanges are available
    if (statistics && dateRanges.length > 0) {
      // Existing code...
  
      // TEAM SUMMARY: Calculate summary for all users
      const allUserActivityAggregates = statistics.activity?.aggregates || [];
      const allUserOpportunitiesAggregates = statistics.opportunities?.aggregates || [];
  
      // Calculate total counts
      const totalCalls = allUserActivityAggregates.reduce(
        (acc: any, item: { calls: any }) => acc + (item.calls || 0),
        0
      );
      const totalEmails = allUserActivityAggregates.reduce(
        (acc: any, item: { emails: any }) => acc + (item.emails || 0),
        0
      );
      const totalMeetings = allUserActivityAggregates.reduce(
        (acc: any, item: { meetings: any }) => acc + (item.meetings || 0),
        0
      );
  
      const totalOpenPipeline = allUserOpportunitiesAggregates.reduce(
        (acc: any, item: { amountOpenPipeline: any }) =>
          acc + (item.amountOpenPipeline || 0),
        0
      );
      const totalClosedWon = allUserOpportunitiesAggregates.reduce(
        (acc: any, item: { amountClosedWon: any }) =>
          acc + (item.amountClosedWon || 0),
        0
      );
      const totalWinRate =
        allUserOpportunitiesAggregates.reduce(
          (acc: any, item: { winRate: any }) => acc + (item.winRate || 0),
          0
        ) / allUserOpportunitiesAggregates.length;
  
      setTeamSummary({
        totalCalls,
        totalEmails,
        totalMeetings,
        totalOpenPipeline,
        totalClosedWon,
        totalWinRate: totalWinRate * 100, // Convert to percentage
      });
  
      // TEAM TABLE: Prepare table data for each user
      const userIds = new Set([
        ...allUserActivityAggregates.map((item: { userId: any; }) => item.userId),
        ...allUserOpportunitiesAggregates.map((item: { userId: any; }) => item.userId),
      ]);
  
      const tableData = Array.from(userIds).map((userId) => {
        const userActivity = allUserActivityAggregates.find(
          (item: { userId: any; }) => item.userId === userId
        ) || { calls: 0, emails: 0, meetings: 0 }; // Default values if no activity
  
        const userOpportunities = allUserOpportunitiesAggregates.find(
          (item: { userId: any; }) => item.userId === userId
        ) || { amountOpenPipeline: 0, amountClosedWon: 0, winRate: null }; // Default values if no opportunities
  
        return {
          userId,
          calls: userActivity.calls,
          emails: userActivity.emails,
          meetings: userActivity.meetings,
          openPipeline: userOpportunities.amountOpenPipeline,
          closedWon: userOpportunities.amountClosedWon,
          winRate: userOpportunities.winRate ? userOpportunities.winRate * 100 : null,
        };
      });
  
      setTeamTable(tableData);
    }
  }, [statistics, dateRanges, currentUser]);
  
  

  return (
    <div className="flex flex-col h-screen">
      <div className="text-white justify-center bg-gradient-to-r from-indigo-300 to-fuchsia-300 h-20 flex flex-col pl-4 items-center text-3xl font-bold w-full">
        Welcome {currentUser.userName}!
      </div>
      <div className="p-4">
        <Select
          value={periodFilter || undefined}
          onValueChange={setPeriodFilter}
        >
          <SelectTrigger className="w-48">
            <SelectValue placeholder="Select period.." />
          </SelectTrigger>
          <SelectContent>
            {dateRanges
              .filter((range) => range.dateRange !== "custom") // Filter out the 'custom' option
              .map((range) => (
                <SelectItem key={range.dateRange} value={range.dateRange}>
                  {range.label}
                </SelectItem>
              ))}
          </SelectContent>
        </Select>
      </div>
      <div className="flex w-full flex-1 space-x-4 mx-auto">
        <div className="flex flex-col space-y-4 pl-4 w-1/2">
          <div className="flex flex-col h-1/2 justify-between">
            <div className="flex h-full w-full space-x-2 h-[100px]">
              <div className="flex flex-col w-1/3  items-center justify-center rounded-lg border mb-2 p-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                  />
                </svg>
                <p className="text-2xl">{userActivity.emails}</p>
                <p className="text-xs">Logged Emails</p>
              </div>

              <div className="flex flex-col w-1/3 items-center justify-center rounded-lg border mb-2 p-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                  />
                </svg>
                <p className="text-2xl">{userActivity.calls}</p>
                <p className="text-xs">Logged Calls</p>
              </div>
              <div className="flex flex-col w-1/3 items-center justify-center rounded-lg border mb-2 p-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
                  />
                </svg>
                <p className="text-2xl">{userActivity.meetings}</p>
                <p className="text-xs">Logged Meetings</p>
              </div>
            </div>
            <div className="h-full w-full rounded-lg border p-2">
              {loading ? (
                <span className="loading loading-spinner loading-md"></span>
              ) : (
                <Chart
                  options={activityOptions}
                  series={activitiesSeries}
                  type="area"
                  height="100%"
                  width="100%"
                />
              )}
            </div>
          </div>
          <div className="flex flex-col h-1/2 justify-between">
            <div className="flex h-full w-full space-x-2 h-[100px]">
              <div className="flex flex-col w-1/3  items-center justify-center rounded-lg border mb-2 p-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z"
                  />
                </svg>

                <p className="text-2xl">
                  {formatNumberWithThousandsSeparator(
                    userOpportunities.amountOpenPipeline,
                    "SEK"
                  )}
                </p>
                <p className="text-xs">Open Pipeline</p>
              </div>

              <div className="flex flex-col w-1/3 items-center justify-center rounded-lg border mb-2 p-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.5 18.75h-9m9 0a3 3 0 0 1 3 3h-15a3 3 0 0 1 3-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 0 1-.982-3.172M9.497 14.25a7.454 7.454 0 0 0 .981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 0 0 7.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 0 0 2.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 0 1 2.916.52 6.003 6.003 0 0 1-5.395 4.972m0 0a6.726 6.726 0 0 1-2.749 1.35m0 0a6.772 6.772 0 0 1-3.044 0"
                  />
                </svg>

                <p className="text-2xl">
                  {formatNumberWithThousandsSeparator(
                    userOpportunities.amountClosedWon,
                    "SEK"
                  )}
                </p>
                <p className="text-xs">Closed Won</p>
              </div>
              <div className="flex flex-col w-1/3 items-center justify-center rounded-lg border mb-2 p-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
                  />
                </svg>

                <p className="text-2xl">
                  {formatNumberWithThousandsSeparator(
                    userOpportunities.winRate
                      ? userOpportunities.winRate * 100
                      : null,
                    "",
                    0,
                    true
                  )}
                </p>
                <p className="text-xs">Win Rate</p>
              </div>
            </div>
            <div className="h-full w-full rounded-lg border p-2">
              {loading ? (
                <span className="loading loading-spinner loading-md"></span>
              ) : (
                <Chart
                  options={opportunityOptions}
                  series={opportunitySeries}
                  type="area"
                  height="100%"
                  width="100%"
                />
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-1 flex-col w-1/2 h-full px-4">
          <div className="flex flex-col">
            <div className="flex h-[100px] w-full space-x-2 ">
              <div className="flex flex-col w-1/5  items-center justify-center rounded-lg border mb-2 p-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                  />
                </svg>
                <p className="text-lg">
                  {teamSummary?.totalEmails || 0}
                </p>
                <p className="text-xs">Logged Emails</p>
              </div>
              <div className="flex flex-col w-1/5  items-center justify-center rounded-lg border mb-2 p-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                  />
                </svg>
                <p className="text-lg">
                  {teamSummary?.totalCalls || 0}
                </p>
                <p className="text-xs">Logged Calls</p>
              </div>
              <div className="flex flex-col w-1/5  items-center justify-center rounded-lg border mb-2 p-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
                  />
                </svg>
                <p className="text-lg">
                  {teamSummary?.totalMeetings || 0}
                </p>
                <p className="text-xs">Logged Meetings</p>
              </div>
              <div className="flex flex-col w-1/5  items-center justify-center rounded-lg border mb-2 p-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z"
                  />
                </svg>
                <p className="text-lg">
                  {formatNumberWithThousandsSeparator(
                    teamSummary?.totalOpenPipeline || 0,
                    "SEK"
                  )}
                </p>
                <p className="text-xs">Open Pipeline</p>
              </div>
              <div className="flex flex-col w-1/5  items-center justify-center rounded-lg border mb-2 p-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.5 18.75h-9m9 0a3 3 0 0 1 3 3h-15a3 3 0 0 1 3-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 0 1-.982-3.172M9.497 14.25a7.454 7.454 0 0 0 .981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 0 0 7.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 0 0 2.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 0 1 2.916.52 6.003 6.003 0 0 1-5.395 4.972m0 0a6.726 6.726 0 0 1-2.749 1.35m0 0a6.772 6.772 0 0 1-3.044 0"
                  />
                </svg>
                <p className="text-lg">
                  {formatNumberWithThousandsSeparator(
                    teamSummary?.totalClosedWon || 0,
                    "SEK"
                  )}
                </p>
                <p className="text-xs">Closed Won</p>
              </div>
              <div className="flex flex-col w-1/5  items-center justify-center rounded-lg border mb-2 p-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z"
                  />
                </svg>
                <p className="text-lg">
                {formatNumberWithThousandsSeparator(
                    teamSummary?.totalWinRate || null,
                    "",
                    0,
                    true
                  )}
                </p>
                <p className="text-xs">Win Rate</p>
              </div>
            </div>
          </div>
          <div className="flex-grow overflow-auto rounded-lg border bg-white shadow-lg">
            <table className="w-full text-left border-collapse">
              <thead>
                <tr className="bg-violet-500 text-white w-full text-sm">
                  <th className="py-2 px-4 border-b">User</th>
                  <th className="py-2 px-4 border-b">Logged Emails</th>
                  <th className="py-2 px-4 border-b">Logged Calls</th>
                  <th className="py-2 px-4 border-b">Logged Meetings</th>
                  <th className="py-2 px-4 border-b">Open Pipeline</th>
                  <th className="py-2 px-4 border-b">Closed Won</th>
                  <th className="py-2 px-4 border-b">Win Rate (%)</th>
                </tr>
              </thead>
              <tbody>
                {teamTable.map((user) => (
                  <tr key={user.userId} className="hover:bg-gray-100 text-sm">
                    <td className="py-2 px-4 border-b">{users.find(x => x.userId === user.userId)?.userName || "Unknown User"}</td>
                    <td className="py-2 px-4 border-b">{user.emails}</td>
                    <td className="py-2 px-4 border-b">{user.calls}</td>
                    <td className="py-2 px-4 border-b">{user.meetings}</td>
                    <td className="py-2 px-4 border-b">
                      {formatNumberWithThousandsSeparator(
                        user.openPipeline,
                        "SEK"
                      )}
                    </td>
                    <td className="py-2 px-4 border-b">
                      {formatNumberWithThousandsSeparator(
                        user.closedWon,
                        "SEK"
                      )}
                    </td>
                    <td className="py-2 px-4 border-b">
                      {user.winRate !== null ? formatNumberWithThousandsSeparator(
                        user.winRate,
                        "",
                        0,
                        true
                      ) : "N/A"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
