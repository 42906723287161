import React, { useEffect, useState } from "react";
import { Sheet, SheetContent, SheetHeader, SheetTitle } from "../ui/sheet";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { createContact, updateContact, deleteContact, getMarketingMessages } from "../../app.service";
import { useAppContext } from "../../AppContext";
import { DateTime } from "luxon";
import { truncateString } from "../methods";
import { notificationType } from "../notification.type";

interface ContactDetailsProps {
  selectedContact: any; // Replace `any` with the correct type of your contact object
  clearSelectedContact: () => void; // Callback function to clear the selected contact
  account: {
    name: string;
    accountId: string;
  };
}

const ContactDetails: React.FC<ContactDetailsProps> = ({
  selectedContact,
  clearSelectedContact,
  account,
}) => {
  const { currentToken } = useAppContext();

  const [isOpen, setIsOpen] = useState(false);
  const [contactId, setContactId] = useState<number | null>(
    selectedContact?.contactId || null
  );
  const [firstName, setFirstName] = useState<string>(
    selectedContact?.firstName || ""
  );
  const [lastName, setLastName] = useState<string>(
    selectedContact?.lastName || ""
  );
  const [title, setTitle] = useState<string>(selectedContact?.title || "");
  const [phone, setPhone] = useState<string>(selectedContact?.phone || "");
  const [email, setEmail] = useState<string>(selectedContact?.email || "");
  const [isEditing, setIsEditing] = useState<boolean>(!selectedContact); // Starts in edit mode if no contact is selected
  const [messages, setMessages] = useState<any[]>([]);

  useEffect(() => {
    if (currentToken) {
      setIsOpen(true);
      fetchMessages();
    }
  }, [selectedContact, currentToken]);

  const handleOpenChange = (open: boolean) => {
    setIsOpen(open);
    if (!open) {
      clearSelectedContact(); // Clear the selectedContact when closing
    }
  };

  const fetchMessages = async () => {
    if (currentToken && selectedContact?.email) {
      const result = await getMarketingMessages(currentToken, selectedContact.email);
      if (result.success) {
        setMessages(result.data);
      }
    }
  };

  const handleSaveContact = async () => {
    const contactObj = {
      firstName,
      lastName,
      title,
      phone,
      email,
      accountId: account.accountId,
    };

    if (contactId && currentToken) {
      // Update existing contact
      const update = await updateContact(contactId, contactObj, currentToken);
      if (update.success) {
        notificationType.success("Success!", update.message)
        setIsEditing(false); // Switch back from editing mode after saving
      } else {
        notificationType.error("Error!", update.message)
      }
    } else if (currentToken) {
      // Create new contact
      const create = await createContact(contactObj, currentToken);
      if (create.success) {
        notificationType.success("Success!", create.message)
        setIsEditing(false); // Switch back from editing mode after saving
      } else {
        notificationType.error("Error!", create.message)
      }
    }
  };

  const handleDeleteContact = async () => {
    if (contactId && currentToken) {
      const deleteResponse = await deleteContact(contactId, currentToken);
      if (deleteResponse.success) {
        clearSelectedContact(); // Clear the selected contact after deleting
      } else {
        console.error(deleteResponse.message);
      }
    }
  };

  return (
    <>
      <Sheet open={isOpen} onOpenChange={handleOpenChange}>
        <SheetContent side="right" className="w-[850px] !max-w-none">
          <SheetHeader className="h-full">
            <SheetTitle className="text-2xl mt-4">
              {isEditing
                ? selectedContact
                  ? "Edit Contact"
                  : "Create Contact"
                : selectedContact?.firstName + " " + selectedContact?.lastName}
            </SheetTitle>
            <div className="flex flex-col justify-between h-full pt-10">
              <div className="flex flex-col gap-4">
                <div className="flex gap-4 mt-6">
                  <span className="flex flex-col gap-1 w-full">
                    <Label>First Name</Label>
                    <Input
                      type="text"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      disabled={!isEditing}
                    />
                  </span>
                  <span className="flex flex-col gap-1 w-full">
                    <Label>Last Name</Label>
                    <Input
                      type="text"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      disabled={!isEditing}
                    />
                  </span>
                </div>
                <div className="flex gap-4 mt-6">
                  <span className="flex flex-col gap-1 w-full">
                    <Label>Title</Label>
                    <Input
                      type="text"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      disabled={!isEditing}
                    />
                  </span>
                </div>
                <div className="flex gap-4 mt-6">
                  <span className="flex flex-col gap-1 w-full">
                    <Label>Phone</Label>
                    <Input
                      type="text"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      disabled={!isEditing}
                    />
                  </span>
                  <span className="flex flex-col gap-1 w-full">
                    <Label>Email</Label>
                    <Input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      disabled={!isEditing}
                    />
                  </span>
                </div>

                {/* Messages section */}
                <div className="flex flex-col h-[28rem] overflow-y-auto p-4 border rounded-lg gap-2">
                  {messages.map((message) => (
                    <div
                      className="flex bg-neutral-100 p-2 text-xs"
                      key={message.UUID}
                    >
                      <span
                        title={message.Subject}
                        className="text-sm w-[35rem] font-semibold text-slate-900"
                      >
                        {truncateString(message.Subject, 60)}
                      </span>
                      <span
                        title={message.Status}
                        className="text-sm font-semibold text-slate-900"
                      >
                        {/* Status icons */}
                        {message.Status}
                      </span>
                      <span className="px-4 text-sm text-slate-500">
                        {DateTime.fromISO(message.ArrivedAt).toFormat(
                          "yyyy-MM-dd HH:MM"
                        )}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex gap-4 mt-6">
                <button
                  className="bg-violet-500 text-white px-4 py-2 rounded-lg"
                  onClick={() => {
                    if (isEditing) {
                      handleSaveContact(); // Save if in edit mode
                    } else {
                      setIsEditing(true); // Enable edit mode
                    }
                  }}
                >
                  {isEditing ? "Save" : "Edit Contact"}
                </button>
                {selectedContact && !isEditing && (
                  <button
                    className="bg-red-500 text-white px-4 py-2 rounded-lg"
                    onClick={handleDeleteContact}
                  >
                    Delete Contact
                  </button>
                )}
              </div>
            </div>
          </SheetHeader>
        </SheetContent>
      </Sheet>
    </>
  );
};

export default ContactDetails;
