import React, { useEffect, useState } from "react";
import { Dialog, DialogClose } from "@radix-ui/react-dialog";
import { Sheet, SheetContent, SheetHeader, SheetTitle } from "../ui/sheet";
import { Input } from "../ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { Checkbox } from "../ui/checkbox";
import { Label } from "../ui/label";
import { DateTime } from "luxon";
import {
  createOpportunity,
  getProducts,
  updateOpportunity,
} from "../../app.service";
import { useAppContext } from "../../AppContext";
import {
  findCurrentConversionRate,
  formatNumberWithThousandsSeparator,
} from "../methods";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import { generateContractPDF } from "./Contract";
import { notificationType } from "../notification.type";

interface OpportunityDetailsProps {
  selectedOpportunity: any; // Replace `any` with the correct type of your opportunity object
  clearSelectedOpportunity: () => void; // Callback function to clear the selected opportunity
  account: {
    partner: any;
    name: string;
    accountId: string;
    primaryContactId: number;
    billingAddress: {
      line1: string;
      line2: string;
      city: string;
      state: string;
      postal_code: string;
      country: string;
    };
  };
}

interface Product {
  productId: number;
  productName: string;
  description: string;
  productType: string;
  listPrices: {
    EUR: number;
    SEK: number;
    USD: number;
  };
  revenueType: string;
  quantity: number;
  quantityUnitOfMeasure: string;
  billingFrequency: string;
  active: number;
  created: string;
  updated: string;
  previousContractLineId: number;
  updatedBy: number;
}

interface ProductSelection {
  previousContractLineId: number;
  productId: number;
  productName: string;
  description: string;
  productType: string;
  quantity: number;
  salesPrice: number;
  overagePrice: number;
  quantityUnitOfMeasure: string;
  revenueType: string;
  isEditing: boolean;
  change: string | null;
}

type Currency = "EUR" | "SEK" | "USD";

const OpportunityDetails: React.FC<OpportunityDetailsProps> = ({
  selectedOpportunity,
  clearSelectedOpportunity,
  account,
}) => {
  const stages = [
    "Prospecting",
    "Qualification",
    "Value Proposition",
    "Proposal/Price Quote",
    "Negotiation/Review",
    "Closed",
  ];

  const lossReasons = [
    "Budget Constraints",
    "Competition",
    "Lack of Trust",
    "Pricing",
    "Product Fit",
    "Timing",
    "Unclear Value Proposition",
    "Unresponsive",
  ];

  const { currentToken, currentUser, conversionRates } = useAppContext();

  const [isOpen, setIsOpen] = useState(false);
  const [opportunityId, setOpportunityId] = useState<number>(
    selectedOpportunity?.opportunityId || ""
  );
  const [stage, setStage] = useState(
    selectedOpportunity?.stage || "Prospecting"
  );
  const [type, setType] = useState(selectedOpportunity?.type || "New Business");
  const [name, setName] = useState<string>(
    selectedOpportunity?.name ||
      `${account.name} - ${type} - ${new Date().getFullYear()}`
  );

  const [products, setProducts] = useState<ProductSelection[]>(
    selectedOpportunity?.products || []
  );
  const [closeDate, setCloseDate] = useState<string>(
    DateTime.fromISO(selectedOpportunity?.closeDate).toISODate() ||
      DateTime.now().plus({ days: 90 }).toISODate()
  );
  const [currency, setCurrency] = useState<Currency>(
    selectedOpportunity?.currency || "SEK"
  );
  const [nextSteps, setNextSteps] = useState<string>(
    selectedOpportunity?.nextSteps
  );
  const [annualRecurringRevenue, setAnnualRecurringRevenue] = useState<number>(
    selectedOpportunity?.annualRecurringRevenue
  );
  const [previousAnnualRecurringRevenue, setPreviousAnnualRecurringRevenue] =
    useState<number>(selectedOpportunity?.previousAnnualRecurringRevenue);
  const [nonRecurringRevenue, setNonRecurringRevenue] = useState<number>(0);
  const [commission, setCommission] = useState<number>(0);
  const [availableProducts, setAvailableProducts] = useState<Product[]>([]);
  const [selectedProductIds, setSelectedProductIds] = useState<number[]>([]);
  const [isAddingProducts, setIsAddingProducts] = useState(false); // Toggle between content and product selection
  const [productInputs, setProductInputs] = useState<{
    [productId: number]: {
      quantity: number;
      salesPrice: number;
      overagePrice: number;
    };
  }>({});
  const [opportunityClosed, setOpportunityClosed] = useState<boolean>(false);
  const [lossReason, setLossReason] = useState<string>(
    selectedOpportunity?.lossReason
  );
  const [lossReasonDescription, setLossReasonDescription] = useState<string>(
    selectedOpportunity?.lossReasonDescription
  );
  const [contractTerm, setContractTerm] = useState<number>(
    selectedOpportunity?.contractTerm
  );
  const [conversionRate, setConversionRate] = useState<number>(1);
  const [ownerId, setOwnerId] = useState<number>(
    selectedOpportunity?.ownerId || currentUser.userId
  );
  const [contractStartDate, setContractStartDate] = useState<string>(
    selectedOpportunity?.contractStartDate || null
  );
  const [noticePeriod, setNoticePeriod] = useState<number>(
    selectedOpportunity?.noticePeriod || 90
  );
  const [autoRenewal, setAutoRenwal] = useState<boolean>(
    selectedOpportunity?.autoRenewal || true
  );
  const [paymentTerm, setPaymentTerm] = useState<number>(
    account.partner ? account.partner.paymentTerm : selectedOpportunity?.paymentTerm || 30
  );
  const [billingFrequency, setBillingFrequency] = useState<string>(
    selectedOpportunity?.billingFrequency || null
  );
  const [previousContractId, setPreviousContractId] = useState<number>(
    selectedOpportunity?.previousContractId || null
  );

  const currentConversionRate = findCurrentConversionRate(
    closeDate,
    currency,
    conversionRates
  );

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [contractError, setContractError] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);

  const opportunityObj = {
    name: name,
    stage: stage,
    nextSteps: nextSteps,
    currency: currency,
    products: products,
    closeDate: closeDate,
    annualRecurringRevenue: annualRecurringRevenue,
    previousAnnualRecurringRevenue: previousAnnualRecurringRevenue,
    nonRecurringRevenue: nonRecurringRevenue,
    lossReason: lossReason,
    lossReasonDescription: lossReasonDescription,
    contractTerm: contractTerm,
    contractStartDate: contractStartDate
      ? contractStartDate.substring(0, 10)
      : null,
    noticePeriod: noticePeriod,
    paymentTerm: paymentTerm,
    autoRenewal: autoRenewal,
    billingFrequency: billingFrequency,
    ownerId: ownerId,
    type: type,
    accountId: account.accountId,
    previousContractId: previousContractId,
  };

  useEffect(() => {
    if (!selectedOpportunity) {
      setName(`${account.name} - ${type} - ${new Date().getFullYear()}`);
    }
  }, [type]);

  useEffect(() => {
    const currentConversionRate = findCurrentConversionRate(
      closeDate,
      currency,
      conversionRates
    );

    if (currentConversionRate !== null && !isNaN(currentConversionRate)) {
      setConversionRate(parseFloat(currentConversionRate.toString()));
    }
  }, [currency]);

  useEffect(() => {
    setIsOpen(true);
    fetchProducts(); // Fetch available products when opportunity is selected

    if (selectedOpportunity) {
      setProducts(selectedOpportunity.products);
      const productIds = selectedOpportunity.products.map(
        (product: ProductSelection) => product.productId
      );
      setSelectedProductIds(productIds);
      // Set products and selected product IDs from the selected opportunity
    }
  }, [selectedOpportunity]);

  const fetchProducts = async () => {
    if (currentToken) {
      const products = await getProducts(currentToken);
      setAvailableProducts(products.data);
    }
  };

  const handleOpenChange = (open: boolean) => {
    setIsOpen(open);
    if (!open) {
      clearSelectedOpportunity(); // Clear the selectedOpportunity when closing
    }
  };

  const handleAddProductClick = () => {
    setIsAddingProducts(true); // Show product selection inside the sheet
  };

  const handleProductSelect = (productId: number) => {
    if (selectedProductIds.includes(productId)) {
      setSelectedProductIds(
        selectedProductIds.filter((id) => id !== productId)
      );
    } else {
      setSelectedProductIds([...selectedProductIds, productId]);

      if (!productInputs[productId]) {
        setProductInputs((prevInputs) => ({
          ...prevInputs,
          [productId]: { quantity: 0, salesPrice: 0, overagePrice: 0 },
        }));
      }
    }
  };

  const handleProductInputChange = (
    productId: number,
    field: keyof ProductSelection,
    value: number
  ) => {
    setProductInputs((prevInputs) => {
      const updatedInputs = {
        ...prevInputs,
        [productId]: { ...prevInputs[productId], [field]: value },
      };

      if (
        field === "quantity" &&
        availableProducts.find((p) => p.productId === productId)
          ?.productName === "Intelly Platform"
      ) {
        const intellyPlatformQuantity = value;
        availableProducts
          .filter((p) => p.productType === "Module")
          .forEach((module) => {
            updatedInputs[module.productId] = {
              ...updatedInputs[module.productId],
              quantity: intellyPlatformQuantity,
            };
          });
      }

      return updatedInputs;
    });

    setProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.productId === productId
          ? { ...product, [field]: value }
          : product.productType === "Module" && field === "quantity"
          ? { ...product, quantity: value }
          : product
      )
    );
  };

  const handleAddSelectedProducts = () => {
    const selectedProducts = availableProducts
      .filter((product) => selectedProductIds.includes(product.productId))
      .map((product) => ({
        productId: product.productId,
        productName: product.productName,
        description: product.description,
        productType: product.productType,
        revenueType: product.revenueType,
        quantityUnitOfMeasure: product.quantityUnitOfMeasure,
        previousContractLineId: product.previousContractLineId,
        change: null,
        listPrice:
          availableProducts.find((x) => x.productId === product.productId)
            ?.listPrices[currency] || 0,
        quantity:
          product.quantity || product.productName === "Intelly Platform"
            ? 50
            : 0,
        salesPrice:
          availableProducts.find((x) => x.productId === product.productId)
            ?.listPrices[currency] || 0,
        overagePrice:
          availableProducts.find((x) => x.productId === product.productId)
            ?.listPrices[currency] || 0,
        isEditing: false,
      }));

    setProducts((prevProducts) => [
      ...prevProducts,
      ...selectedProducts.filter(
        (product) =>
          !prevProducts.some((p) => p.productId === product.productId)
      ),
    ]); // Add selected products to the list without duplicates

    setIsAddingProducts(false); // Go back to the main content in the sheet
  };

  const handleCancelAddProducts = () => {
    setIsAddingProducts(false); // Cancel product selection and return to main content
  };

  const handleEditProduct = (productId: number) => {
    setProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.productId === productId
          ? { ...product, isEditing: !product.isEditing }
          : product
      )
    );
  };

  const handleDeleteProduct = (productId: number) => {
    setProducts((prevProducts) =>
      prevProducts.filter((product) => product.productId !== productId)
    );
  };

  const handleProductChange = (
    productId: number,
    field: keyof ProductSelection,
    value: string | number
  ) => {
    setProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.productId === productId
          ? { ...product, [field]: value }
          : product
      )
    );
  };

  const handleUpdateOpportunity = async () => {
    setSaveLoading(true);
    if (!selectedOpportunity && currentToken) {
      const create = await createOpportunity(opportunityObj, currentToken);
      if (create.success) {
        notificationType.success("Opportunity created!");
        closeSheet();
      }
      setTimeout(() => {
        setSaveLoading(false);
      }, 1500);
    } else {
      if (stage === "Closed") {
        if (!account.billingAddress?.country || !account.primaryContactId) {
          notificationType.error(
            "Missing fields!",
            "Please ensure Primary Contact and Country is set before closing"
          );
        } else {
          setOpportunityClosed(true);
        }
        setSaveLoading(false);
      } else {
        if (currentToken) {
          const update = await updateOpportunity(
            opportunityId,
            opportunityObj,
            currentToken
          );
          if (update.success) {
            setTimeout(() => {
              setSaveLoading(false);
              notificationType.success("Opportunity updated!");
            }, 1500);

            if (stage === "Closed Won" || stage === "Closed Lost") {
              closeSheet();
            }
          } else {
            setTimeout(() => {
              setSaveLoading(false);
              notificationType.error("Error!", update.message);
            }, 1500);
          }
        }
      }
    }
  };

  const closeSheet = () => {
    setIsOpen(false);
    clearSelectedOpportunity(); // Call the parent callback to clear selectedOpportunity
  };

  useEffect(() => {
    const intellyPlatform = products.find(
      (product) => product.productName === "Intelly Platform"
    );

    if (intellyPlatform) {
      setProducts((prevProducts) =>
        prevProducts.map((product) =>
          product.productType === "Module"
            ? { ...product, quantity: intellyPlatform.quantity }
            : product
        )
      );
    }
  }, [products.find((x) => x.productName === "Intelly Platform")?.quantity]);

  useEffect(() => {
    if (products.length > 0) {
      let licenseCount = 0;
      let licensePrice = 0;
      let nonRecurringRevenue = 0;
      products.forEach((product) => {
        if (product.revenueType === "Recurring") {
          if (product.productName === "Intelly Platform") {
            licenseCount = product.quantity;
          }
          licensePrice += product.salesPrice;
        } else if (product.revenueType === "Non-recurring") {
          nonRecurringRevenue += product.salesPrice * product.quantity;
        }
      });

      const contractLength = contractTerm ? contractTerm : 12;

      const recurringRevenue = licensePrice * licenseCount * 12;

      setAnnualRecurringRevenue(recurringRevenue);
      setNonRecurringRevenue(nonRecurringRevenue);

      if (type === "New Business") {
        const commissionPercentage =
          currentUser?.commissionModel["New Business"][
            contractLength.toString()
          ] ?? null;
        if (ownerId === currentUser.userId && commissionPercentage !== null) {
          const currentCommission =
            (recurringRevenue + nonRecurringRevenue) * commissionPercentage;

          setCommission(currentCommission);
        }
      } else if (type === "Up-sell") {
        const previousContractEndDate = DateTime.fromISO(
          selectedOpportunity.previousContract.contractEndDate
        );
        const today = DateTime.now();

        // Extract month and day from the previous contract's end date
        const month = previousContractEndDate.month;
        const day = previousContractEndDate.day;

        // Check if today's date is before or after the month and day of the previous contract
        let closestNextDate;
        if (today.month > month || (today.month === month && today.day > day)) {
          // If today's date is after the month and day, pick the date for next year
          closestNextDate = DateTime.fromObject({
            year: today.year + 1,
            month: month,
            day: day,
          });
        } else {
          // If today's date is before the month and day, pick the date for this year
          closestNextDate = DateTime.fromObject({
            year: today.year,
            month: month,
            day: day,
          });
        }

        // Assuming closestNextDate is already calculated and is a Luxon DateTime object
        const contractStartDate = DateTime.fromISO(
          selectedOpportunity.contractStartDate
        );

        // Calculate the difference in months
        const differenceInMonths = closestNextDate.diff(
          contractStartDate,
          "months"
        ).months;

        let addedRecurring = 0;
        let addedNonRecurring = 0;
        products.forEach((product) => {
          if (
            product.revenueType === "Non-recurring" &&
            product.change === "Added"
          ) {
            addedNonRecurring += product.quantity * product.salesPrice;
          }

          if (
            product.revenueType === "Recurring" &&
            product.change === "Added"
          ) {
            addedRecurring +=
              product.quantity * product.salesPrice * differenceInMonths;
          }

          if (
            product.revenueType === "Recurring" &&
            product.change === "Updated"
          ) {
            const previousContractLine =
              selectedOpportunity?.previousContract?.contractLines.find(
                (x: { contractLineId: number }) =>
                  x.contractLineId === product.previousContractLineId
              );
            if (previousContractLine) {
              const quantityDiff =
                product.quantity - previousContractLine.quantity;
              addedRecurring +=
                quantityDiff * product.salesPrice * differenceInMonths;
            }
          }
        });

        const addedRevenue = Math.round(addedRecurring + addedNonRecurring);

        const commissionPercentage =
          currentUser?.commissionModel["Up-sell"] ?? null;

        if (
          (ownerId === currentUser.userId ||
            currentUser.userRole === "Admin") &&
          commissionPercentage !== null
        ) {
          const currentCommission = addedRevenue * commissionPercentage;

          setCommission(currentCommission);
        }
        // TODO: Calculate comission
      } else if (type === "Renewal") {
      }
    } else {
      setAnnualRecurringRevenue(0);
      setNonRecurringRevenue(0);
    }
  }, [products, contractTerm]);

  const saveAndCloseDisabled = () => {
    if (stage === "Closed") {
      return true;
    }

    if (stage === "Closed Lost" && (!lossReason || !lossReasonDescription)) {
      return true;
    }

    if (
      stage === "Closed Won" &&
      (!contractStartDate || // Always check if contractStartDate is empty
        (type !== "Up-sell" && // Check other fields only if type is not "Up-sell"
           (type === "Customer Order Form" ? (!contractTerm) : (!contractTerm || !noticePeriod || !paymentTerm || !currency))))
    ) {
      return true;
    }

    return false;
  };

  return (
    <>
      <Sheet open={isOpen} onOpenChange={handleOpenChange}>
        <SheetContent side="right" className="w-[900px] !max-w-none">
          {opportunityClosed ? (
            <SheetHeader className="h-full flex justify-between">
              <div>
                <SheetTitle className="text-2xl mb-12">
                  Close Opportunity
                </SheetTitle>
                <span className="flex flex-col gap-2 w-full">
                  <Label>Stage</Label>
                  <Select value={stage || undefined} onValueChange={setStage}>
                    <SelectTrigger className="w-1/2 mb-4">
                      <SelectValue placeholder="Update stage" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem key={"Closed Lost"} value={"Closed Lost"}>
                        Closed Lost
                      </SelectItem>
                      <SelectItem key={"Closed Won"} value={"Closed Won"}>
                        Closed Won
                      </SelectItem>
                    </SelectContent>
                  </Select>
                </span>
                {stage === "Closed Won" && (
                  <div className="flex w-full">
                    <span className="flex w-full text-sm">
                      <div className="w-full">
                        <div className="flex gap-2 mt-6">
                          <span className="flex flex-col gap-2 w-full">
                            <Label>Start Date</Label>
                            <Input
                              className="w-1/2"
                              type="date"
                              disabled={type === "Renewal"}
                              value={
                                contractStartDate
                                  ? contractStartDate.split("T")[0]
                                  : ""
                              }
                              onChange={(e) =>
                                setContractStartDate(e.target.value)
                              }
                            />
                            {contractError && !contractStartDate && (
                              <p className="text-red-500 text-xs pl-1">
                                Please fill in Start Date to generate PDF.
                              </p>
                            )}
                          </span>
                          {type !== "Up-sell" && (
                            <span className="flex flex-col gap-2 w-full">
                              <Label htmlFor="type">Contract Term</Label>
                              <Select
                                value={contractTerm?.toString() ?? ""}
                                onValueChange={(value: string) =>
                                  setContractTerm(parseInt(value))
                                }
                              >
                                <SelectTrigger className="w-1/2">
                                  <SelectValue placeholder="Pick a term.." />
                                </SelectTrigger>
                                <SelectContent>
                                  <SelectItem value="12">12 months</SelectItem>
                                  <SelectItem value="24">24 months</SelectItem>
                                  <SelectItem value="36">36 months</SelectItem>
                                </SelectContent>
                              </Select>
                              {contractError && !contractTerm && (
                                <p className="text-red-500 text-xs pl-1">
                                  Please fill in Contract Term to generate PDF.
                                </p>
                              )}
                            </span>
                          )}
                        </div>
                        {type !== "Up-sell" && type !== "Customer Order Form" && (
                          <>
                            <div className="flex gap-2 mt-6">
                              <span className="flex flex-col gap-2 w-full">
                                <Label>Notice Period</Label>
                                <Select
                                  value={noticePeriod?.toString() ?? ""}
                                  onValueChange={(value: string) =>
                                    setNoticePeriod(parseInt(value))
                                  }
                                >
                                  <SelectTrigger className="w-1/2">
                                    <SelectValue placeholder="Pick a term.." />
                                  </SelectTrigger>
                                  <SelectContent>
                                    <SelectItem value="60">60 days</SelectItem>
                                    <SelectItem value="90">90 days</SelectItem>
                                    <SelectItem value="120">
                                      120 days
                                    </SelectItem>
                                  </SelectContent>
                                </Select>
                                {contractError && !noticePeriod && (
                                  <p className="text-red-500 text-xs pl-1">
                                    Please fill in Notice Period to generate
                                    PDF.
                                  </p>
                                )}
                              </span>
                              <span className="flex flex-col gap-2 w-full">
                                <Label htmlFor="type">Contract Term</Label>
                                <Select
                                  value={contractTerm?.toString() ?? ""}
                                  onValueChange={(value: string) =>
                                    setContractTerm(parseInt(value))
                                  }
                                >
                                  <SelectTrigger className="w-1/2">
                                    <SelectValue placeholder="Pick a term.." />
                                  </SelectTrigger>
                                  <SelectContent>
                                    <SelectItem value="12">
                                      12 months
                                    </SelectItem>
                                    <SelectItem value="24">
                                      24 months
                                    </SelectItem>
                                    <SelectItem value="36">
                                      36 months
                                    </SelectItem>
                                  </SelectContent>
                                </Select>
                                {contractError && !contractTerm && (
                                  <p className="text-red-500 text-xs pl-1">
                                    Please fill in Contract Term to generate
                                    PDF.
                                  </p>
                                )}
                              </span>
                            </div>
                            <div className={`flex gap-2 mt-6`}>
                              <span className="flex flex-col gap-2 w-1/2">
                                <Label htmlFor="type">Auto Renewal</Label>
                                <div className="flex items-center py-2">
                                  <input
                                    type="checkbox"
                                    checked={autoRenewal}
                                    onChange={(e) =>
                                      setAutoRenwal(e.target.checked)
                                    }
                                    className="w-4 h-4"
                                  />
                                </div>
                              </span>
                              <span className="flex flex-col gap-2 w-1/2">
                                <Label htmlFor="type">Currency</Label>
                                <Select
                                  value={currency || undefined}
                                  onValueChange={(value: string) =>
                                    setCurrency(value as Currency)
                                  }
                                >
                                  <SelectTrigger className="w-1/2">
                                    <SelectValue placeholder="SEK" />
                                  </SelectTrigger>
                                  <SelectContent>
                                    <SelectItem value="SEK">SEK</SelectItem>
                                    <SelectItem value="USD">USD</SelectItem>
                                    <SelectItem value="EUR">EUR</SelectItem>
                                  </SelectContent>
                                </Select>
                                {contractError && !currency && (
                                  <p className="text-red-500 text-xs pl-1">
                                    Please fill in Currency to generate PDF.
                                  </p>
                                )}
                              </span>
                            </div>
                          </>
                        )}
                      </div>
                    </span>
                  </div>
                )}

                {stage === "Closed Lost" && (
                  <div>
                    <span className="flex flex-col gap-2 w-full">
                      <Label>Loss Reason</Label>
                      <Select
                        value={lossReason || undefined}
                        onValueChange={setLossReason}
                      >
                        <SelectTrigger className="w-1/2 mb-4">
                          <SelectValue placeholder="Enter loss reason.." />
                        </SelectTrigger>
                        <SelectContent>
                          {lossReasons.map((lossReason) => (
                            <SelectItem key={lossReason} value={lossReason}>
                              {lossReason}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </span>
                    <span className="flex flex-col gap-2 w-full">
                      <Label>Comments</Label>
                      <textarea
                        className="w-full rounded-2xl p-2 text-sm bg-neutral-100"
                        value={lossReasonDescription}
                        onChange={(e) =>
                          setLossReasonDescription(e.target.value)
                        }
                        maxLength={255}
                      />
                    </span>
                  </div>
                )}
              </div>
              <div>
                <div className="flex gap-4">
                  <div className="self-start">
                    <button
                      className={`mt-4 ${
                        saveAndCloseDisabled()
                          ? "bg-neutral-300 cursor-not-allowed"
                          : "bg-violet-500"
                      } text-white px-4 py-2 rounded`}
                      disabled={saveAndCloseDisabled()}
                      onClick={handleUpdateOpportunity}
                    >
                      Save & Close
                    </button>
                  </div>
                  <button
                    className="mt-4 bg-gray-400 text-white px-4 py-2 rounded"
                    onClick={() => {
                      setOpportunityClosed(false);
                      setStage("Closed");
                    }}
                  >
                    Back
                  </button>
                </div>
              </div>
            </SheetHeader>
          ) : (
            <>
              <SheetHeader className="flex w-full h-full">
                <SheetTitle className="text-2xl mt-4">
                  {isAddingProducts ? (
                    <div>Select Products</div>
                  ) : (
                    <div className="flex w-full justify-between">
                      <span className="w-full">{name}</span>
                      {annualRecurringRevenue > 0 ? (
                        <span className="flex w-full gap-2 justify-end">
                          ARR:{" "}
                          {formatNumberWithThousandsSeparator(
                            annualRecurringRevenue,
                            ` ${currency}`
                          )}
                          {currency !== "SEK" && (
                            <div>
                              {"("}
                              {formatNumberWithThousandsSeparator(
                                annualRecurringRevenue * conversionRate,
                                ` SEK`
                              )}
                              {")"}
                            </div>
                          )}
                        </span>
                      ) : (
                        <span className="flex pr-4 whitespace-nowrap justify-end">
                          ARR: -
                        </span>
                      )}
                    </div>
                  )}
                </SheetTitle>

                <div className="flex flex-col justify-between h-full pt-10">
                  <div className="flex gap-[3px]">
                    {stages.map((mappedStage, index) => {
                      const isClosedWon = stage === "Closed Won";
                      const isClosedLost = stage === "Closed Lost";
                      const currentStageIndex = stages.indexOf(stage);
                      const isActive = index === currentStageIndex;

                      return (
                        <div
                          key={mappedStage}
                          className={`h-12 w-full flex items-center justify-center text-xs font-medium ${
                            isClosedWon
                              ? "bg-green-400 text-black"
                              : isClosedLost
                              ? "bg-rose-400 text-white"
                              : isActive
                              ? "bg-green-500 text-white"
                              : index < currentStageIndex
                              ? "bg-green-300 text-green-700"
                              : "bg-slate-300 text-slate-700/70"
                          } ${
                            index === 0
                              ? "rounded-l-full"
                              : index === stages.length - 1
                              ? "rounded-r-full"
                              : ""
                          }`}
                        >
                          {mappedStage}
                        </div>
                      );
                    })}
                  </div>
                  <TabGroup
                    selectedIndex={selectedIndex}
                    onChange={(index) => setSelectedIndex(index)}
                    className={"flex flex-col pt-4"}
                  >
                    <TabList className={"flex "}>
                      {["Details", "Products", "Contracting"].map(
                        (label, index) => (
                          <Tab
                            key={label}
                            className={`flex flex-col border-x border-t px-4 py-1 w-32 p-4 items-center justify-center ${
                              selectedIndex === index
                                ? "border-t-violet-500 border-t-4"
                                : "bg-neutral-50"
                            } first:rounded-tl-lg [&:nth-child(3)]:rounded-tr-lg`}
                            onClick={() => setSelectedIndex(index)}
                          >
                            {label === "Details" && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="size-5"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Zm6-10.125a1.875 1.875 0 1 1-3.75 0 1.875 1.875 0 0 1 3.75 0Zm1.294 6.336a6.721 6.721 0 0 1-3.17.789 6.721 6.721 0 0 1-3.168-.789 3.376 3.376 0 0 1 6.338 0Z"
                                />
                              </svg>
                            )}

                            {label === "Products" && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="size-5"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z"
                                />
                              </svg>
                            )}

                            {label === "Contracting" && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="size-5"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m3.75 9v7.5m2.25-6.466a9.016 9.016 0 0 0-3.461-.203c-.536.072-.974.478-1.021 1.017a4.559 4.559 0 0 0-.018.402c0 .464.336.844.775.994l2.95 1.012c.44.15.775.53.775.994 0 .136-.006.27-.018.402-.047.539-.485.945-1.021 1.017a9.077 9.077 0 0 1-3.461-.203M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                                />
                              </svg>
                            )}
                            {label}
                          </Tab>
                        )
                      )}
                      <Tab disabled className={"flex flex-grow border-b"}></Tab>
                    </TabList>
                    <TabPanels
                      className={
                        "flex border-x border-b rounded-bl-lg rounded-br-lg p-4 h-[38rem]"
                      }
                    >
                      <TabPanel className={"flex w-full"}>
                        <div className="w-full">
                          <div className="flex gap-2 mt-6">
                            <span className="flex flex-col gap-2 w-full">
                              <Label>Stage</Label>
                              <Select
                                value={stage || undefined}
                                onValueChange={setStage}
                              >
                                <SelectTrigger className="w-1/2">
                                  <SelectValue placeholder="Select stage.." />
                                </SelectTrigger>
                                <SelectContent>
                                  {stages.map((stage) => {
                                    let disabled = false;
                                    if (
                                      stage === "Closed" &&
                                      !selectedOpportunity
                                    ) {
                                      disabled = true;
                                    }

                                    return (
                                      <SelectItem
                                        key={stage}
                                        disabled={disabled}
                                        value={stage}
                                      >
                                        {stage}
                                      </SelectItem>
                                    );
                                  })}
                                </SelectContent>
                              </Select>
                            </span>
                            <span className="flex flex-col gap-2 w-full">
                              <Label htmlFor="type">Type</Label>
                              <Select
                                // disabled={true}
                                value={type || undefined}
                                onValueChange={setType}
                              >
                                <SelectTrigger className="w-1/2">
                                  <SelectValue placeholder="Select type.." />
                                </SelectTrigger>
                                <SelectContent>
                                  <SelectItem value="New Business">
                                    New Business
                                  </SelectItem>
                                  <SelectItem disabled value="Up-sell">
                                    Up-sell
                                  </SelectItem>
                                  <SelectItem disabled value="Renewal">
                                    Renewal
                                  </SelectItem>
                                  {account.partner && (
                                    <SelectItem value="Customer Order Form">
                                      Customer Order Form
                                    </SelectItem>
                                  )}
                                </SelectContent>
                              </Select>
                            </span>
                          </div>
                          <div className="flex gap-2 mt-6">
                            <span className="flex flex-col gap-2 w-full">
                              <Label>Close Date</Label>
                              <Input
                                className="w-1/2"
                                type="date"
                                value={closeDate}
                                onChange={(e) => setCloseDate(e.target.value)}
                              />
                            </span>
                            <span className="flex flex-col gap-2 w-full">
                              <Label htmlFor="type">Currency</Label>
                              <Select
                                value={currency || undefined}
                                onValueChange={(value: string) =>
                                  setCurrency(value as Currency)
                                }
                                disabled={type === "Up-sell"}
                              >
                                <SelectTrigger className="w-1/2">
                                  <SelectValue placeholder="SEK" />
                                </SelectTrigger>
                                <SelectContent>
                                  <SelectItem value="SEK">SEK</SelectItem>
                                  <SelectItem value="USD">USD</SelectItem>
                                  <SelectItem value="EUR">EUR</SelectItem>
                                </SelectContent>
                              </Select>
                            </span>
                          </div>

                          <div className={`flex gap-2 mt-6`}>
                            {type !== "Up-sell" && (
                              <span className="flex flex-col gap-2 w-1/2">
                                <Label htmlFor="type">Contract Term</Label>
                                <Select
                                  value={contractTerm?.toString() ?? ""}
                                  onValueChange={(value: string) =>
                                    setContractTerm(parseInt(value))
                                  }
                                >
                                  <SelectTrigger className="w-1/2">
                                    <SelectValue placeholder="Pick a term.." />
                                  </SelectTrigger>
                                  <SelectContent>
                                    <SelectItem value="12">
                                      12 months
                                    </SelectItem>
                                    <SelectItem value="24">
                                      24 months
                                    </SelectItem>
                                    <SelectItem value="36">
                                      36 months
                                    </SelectItem>
                                  </SelectContent>
                                </Select>
                              </span>
                            )}

                            <span
                              className={`flex flex-col gap-2 w-1/2 ${
                                ownerId !== currentUser.userId
                                  ? "invisible"
                                  : ""
                              }`}
                            >
                              <Label htmlFor="type">Commission </Label>
                              {type === "Up-sell" && stage !== "Closed Won" ? (
                                <p className="italic">
                                  Calculated on Closed Won
                                </p>
                              ) : (
                                <p>
                                  {formatNumberWithThousandsSeparator(
                                    commission * conversionRate,
                                    ` SEK`
                                  )}
                                </p>
                              )}
                            </span>
                          </div>
                          <div className={`flex gap-2 mt-6`}>
                            {type !== "Up-sell" && (
                              <span className="flex flex-col gap-2 w-1/2">
                                <Label htmlFor="type">Billing Frequency</Label>
                                <Select
                                  value={billingFrequency ?? ""}
                                  onValueChange={(value: string) =>
                                    setBillingFrequency(value)
                                  }
                                >
                                  <SelectTrigger className="w-1/2">
                                    <SelectValue placeholder="Pick a frequency.." />
                                  </SelectTrigger>
                                  <SelectContent>
                                    <SelectItem value="Yearly">
                                      Yearly
                                    </SelectItem>
                                    <SelectItem value="Monthly">
                                      Monthly
                                    </SelectItem>
                                  </SelectContent>
                                </Select>
                              </span>
                            )}

                            <span
                              className={`flex flex-col gap-2 w-1/2 ${
                                type === "Customer Order Form" || ownerId !== currentUser.userId
                                  ? "invisible"
                                  : ""
                              }`}
                            >
                              <Label htmlFor="type">Commission </Label>
                              {type === "Up-sell" && stage !== "Closed Won" ? (
                                <p className="italic">
                                  Calculated on Closed Won
                                </p>
                              ) : (
                                <p>
                                  {formatNumberWithThousandsSeparator(
                                    commission * conversionRate,
                                    ` SEK`
                                  )}
                                </p>
                              )}
                            </span>
                          </div>
                          <div className="flex gap-2 mt-6">
                            <span className="flex flex-col gap-2 w-full">
                              <Label>Next Steps</Label>
                              <textarea
                                className="w-full rounded-2xl p-2 text-sm bg-neutral-100 resize-none"
                                value={nextSteps}
                                onChange={(e) => setNextSteps(e.target.value)}
                                maxLength={255}
                                rows={5}
                              />
                            </span>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel className={"flex w-full"}>
                        {isAddingProducts ? (
                          // Product selection content inside the sheet
                          <div className="flex flex-col">
                            <ul>
                              {availableProducts.map((product) => (
                                <li
                                  key={product.productId}
                                  className="flex items-center justify-between mb-2 h-18 bg-neutral-200/50 rounded-2xl p-4 my-4"
                                >
                                  <div className="flex w-[20rem] h-full items-center">
                                    <Checkbox
                                      checked={selectedProductIds.includes(
                                        product.productId
                                      )}
                                      onCheckedChange={() =>
                                        handleProductSelect(product.productId)
                                      }
                                    />
                                    <span className="ml-2 font-bold text-sm">
                                      {product.productName}
                                    </span>
                                  </div>
                                  <div className="flex w-full">
                                    <span className="ml-2 text-sm">
                                      {product.description}
                                    </span>
                                  </div>
                                </li>
                              ))}
                            </ul>
                            <div className="flex justify-end gap-4 mt-6">
                              <button
                                className="bg-gray-400 text-white px-4 py-2 rounded"
                                onClick={handleCancelAddProducts}
                              >
                                Cancel
                              </button>
                              <button
                                className="bg-violet-500 text-white px-4 py-2 rounded"
                                onClick={handleAddSelectedProducts}
                              >
                                Add
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div className="flex h-full w-full">
                            {/* Add product section */}
                            <div className="flex flex-col justify-start items-start space-x-2 mt-4">
                              <div className="flex w-full justify-between items-center h-10">
                                <p className="text-sm font-semibold">
                                  Products:
                                </p>
                                <button
                                  className="bg-violet-500 h-6 text-white px-4 py-2 rounded-lg flex items-center gap-3 text-sm"
                                  onClick={handleAddProductClick}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="size-5"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                    />
                                  </svg>
                                  Add Products
                                </button>
                              </div>

                              <div className="overflow-y-auto h-full w-full">
                                {products.length > 0 ? (
                                  <ul>
                                    {products.map(
                                      (product: ProductSelection) => (
                                        <li
                                          key={product.productId}
                                          className="flex w-full items-center justify-between mb-2 h-18 bg-neutral-200/50 rounded-2xl p-4 my-4 hover:bg-blue-50"
                                        >
                                          <span className="font-bold w-48 text-sm">
                                            {product.productName}
                                          </span>
                                          {product.isEditing ? (
                                            <div className="flex gap-4 items-center mr-4">
                                              <div className="text-sm w-[8rem]">
                                                <Label className="text-sm font-bold">
                                                  Quantity
                                                </Label>
                                                <Input
                                                  type="number"
                                                  disabled={
                                                    !selectedProductIds.includes(
                                                      product.productId
                                                    ) ||
                                                    product.productType ===
                                                      "Module"
                                                  }
                                                  value={product.quantity}
                                                  onChange={(e) =>
                                                    handleProductChange(
                                                      product.productId,
                                                      "quantity",
                                                      +e.target.value
                                                    )
                                                  }
                                                  placeholder="Quantity"
                                                />
                                              </div>
                                              <div className="text-sm w-[8rem]">
                                                <Label className="text-sm font-bold">
                                                  Sales Price
                                                </Label>
                                                <Input
                                                  type="number"
                                                  disabled={
                                                    !selectedProductIds.includes(
                                                      product.productId
                                                    )
                                                  }
                                                  value={product.salesPrice}
                                                  onChange={(e) =>
                                                    handleProductChange(
                                                      product.productId,
                                                      "salesPrice",
                                                      +e.target.value
                                                    )
                                                  }
                                                  placeholder="Sales Price"
                                                />
                                              </div>
                                              <div
                                                className={`text-sm w-[8rem] ${
                                                  product.productType ===
                                                  "Professional Services"
                                                    ? "invisible"
                                                    : ""
                                                }`}
                                              >
                                                <Label className="text-sm font-bold">
                                                  Overage Price
                                                </Label>
                                                <Input
                                                  type="number"
                                                  value={product.overagePrice}
                                                  onChange={(e) =>
                                                    handleProductChange(
                                                      product.productId,
                                                      "overagePrice",
                                                      +e.target.value
                                                    )
                                                  }
                                                  placeholder="Overage Price"
                                                />
                                              </div>
                                            </div>
                                          ) : (
                                            <div className="flex gap-4 items-center p-4">
                                              <div
                                                className={`flex  flex-col text-sm w-[8rem]`}
                                              >
                                                <Label className="font-bold">
                                                  Quantity
                                                </Label>
                                                <span>{product.quantity}</span>
                                              </div>
                                              <div
                                                className={`flex flex-col text-sm w-[8rem] `}
                                              >
                                                <Label className="font-bold">
                                                  Sales Price
                                                </Label>
                                                <span>
                                                  {product.salesPrice.toFixed(
                                                    0
                                                  )}{" "}
                                                  {currency}
                                                </span>
                                              </div>
                                              <div
                                                className={`flex  flex-col text-sm w-[8rem] ${
                                                  product.productType ===
                                                  "Professional Services"
                                                    ? "invisible"
                                                    : ""
                                                }`}
                                              >
                                                <Label className="font-bold">
                                                  Overage Price
                                                </Label>
                                                <span>
                                                  {product.overagePrice}{" "}
                                                  {currency}
                                                </span>
                                              </div>
                                            </div>
                                          )}
                                          <div className="flex gap-4">
                                            <button
                                              className="bg-violet-300 text-white p-2 rounded-full"
                                              onClick={() =>
                                                handleEditProduct(
                                                  product.productId
                                                )
                                              }
                                            >
                                              {product.isEditing ? (
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  fill="none"
                                                  viewBox="0 0 24 24"
                                                  strokeWidth={1.5}
                                                  stroke="currentColor"
                                                  className="size-5"
                                                >
                                                  <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                                  />
                                                </svg>
                                              ) : (
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  fill="none"
                                                  viewBox="0 0 24 24"
                                                  strokeWidth={1.5}
                                                  stroke="currentColor"
                                                  className="size-5"
                                                >
                                                  <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                                                  />
                                                </svg>
                                              )}
                                            </button>
                                            <button
                                              className="bg-red-400 text-white p-2 rounded-full"
                                              onClick={() =>
                                                handleDeleteProduct(
                                                  product.productId
                                                )
                                              }
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth={1.5}
                                                stroke="currentColor"
                                                className="size-5"
                                              >
                                                <path
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                  d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                                />
                                              </svg>
                                            </button>
                                          </div>
                                        </li>
                                      )
                                    )}
                                  </ul>
                                ) : (
                                  <p className="text-sm">No products added</p>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </TabPanel>
                      <TabPanel className={"flex w-full"}>
                        <div className="w-full">
                          <div className="flex gap-2 mt-6">
                            <span className="flex flex-col gap-2 w-full">
                              <Label>Start Date</Label>
                              <Input
                                className="w-1/2"
                                type="date"
                                disabled={type === "Renewal"}
                                value={
                                  contractStartDate
                                    ? contractStartDate.split("T")[0]
                                    : ""
                                }
                                onChange={(e) =>
                                  setContractStartDate(e.target.value)
                                }
                              />
                              {contractError && !contractStartDate && (
                                <p className="text-red-500 text-xs pl-1">
                                  Please fill in Start Date to generate PDF.
                                </p>
                              )}
                            </span>
                            {type !== "Up-sell" && (
                              <span className="flex flex-col gap-2 w-full">
                                <Label htmlFor="type">Contract Term</Label>
                                <Select
                                  value={contractTerm?.toString() ?? ""}
                                  onValueChange={(value: string) =>
                                    setContractTerm(parseInt(value))
                                  }
                                >
                                  <SelectTrigger className="w-1/2">
                                    <SelectValue placeholder="Pick a term.." />
                                  </SelectTrigger>
                                  <SelectContent>
                                    <SelectItem value="12">
                                      12 months
                                    </SelectItem>
                                    <SelectItem value="24">
                                      24 months
                                    </SelectItem>
                                    <SelectItem value="36">
                                      36 months
                                    </SelectItem>
                                  </SelectContent>
                                </Select>
                                {contractError && !contractTerm && (
                                  <p className="text-red-500 text-xs pl-1">
                                    Please fill in Contract Term to generate
                                    PDF.
                                  </p>
                                )}
                              </span>
                            )}
                          </div>
                          {type !== "Up-sell" && (
                            <div className="flex gap-2 mt-6">
                              <span className="flex flex-col gap-2 w-full">
                                <Label>Notice Period</Label>
                                <Select
                                  value={noticePeriod?.toString() ?? ""}
                                  onValueChange={(value: string) =>
                                    setNoticePeriod(parseInt(value))
                                  }
                                >
                                  <SelectTrigger className="w-1/2">
                                    <SelectValue placeholder="Pick a term.." />
                                  </SelectTrigger>
                                  <SelectContent>
                                    <SelectItem value="60">60 days</SelectItem>
                                    <SelectItem value="90">90 days</SelectItem>
                                    <SelectItem value="120">
                                      120 days
                                    </SelectItem>
                                  </SelectContent>
                                </Select>
                                {contractError && !noticePeriod && (
                                  <p className="text-red-500 text-xs pl-1">
                                    Please fill in Notice Period to generate
                                    PDF.
                                  </p>
                                )}
                              </span>
                              <span className="flex flex-col gap-2 w-full">
                                <Label htmlFor="type">Payment Term</Label>
                                <Select
                                  disabled={account.partner}
                                  value={account.partner ? account.partner.paymentTerm : paymentTerm?.toString() ?? ""}
                                  onValueChange={(value: string) =>
                                    setPaymentTerm(parseInt(value))
                                  }
                                >
                                  <SelectTrigger className="w-1/2">
                                    <SelectValue placeholder="30" />
                                  </SelectTrigger>
                                  <SelectContent>
                                    <SelectItem value="30">30 days</SelectItem>
                                    <SelectItem value="45">45 days</SelectItem>
                                    <SelectItem value="60">60 days</SelectItem>
                                  </SelectContent>
                                </Select>
                                {contractError && !paymentTerm && (
                                  <p className="text-red-500 text-xs pl-1">
                                    Please fill in Payment Term to generate PDF.
                                  </p>
                                )}
                              </span>
                            </div>
                          )}
                          {type !== "Up-sell" && (
                            <div className={`flex gap-2 mt-6`}>
                              <span className="flex flex-col gap-2 w-1/2">
                                <Label htmlFor="type">Auto Renewal</Label>
                                <div className="flex items-center py-2">
                                  <input
                                    type="checkbox"
                                    checked={autoRenewal}
                                    onChange={(e) =>
                                      setAutoRenwal(e.target.checked)
                                    }
                                    className="w-4 h-4"
                                  />
                                </div>
                              </span>
                              <span className="flex flex-col gap-2 w-1/2">
                                <Label htmlFor="type">Currency</Label>
                                <Select
                                  value={currency || undefined}
                                  onValueChange={(value: string) =>
                                    setCurrency(value as Currency)
                                  }
                                >
                                  <SelectTrigger className="w-1/2">
                                    <SelectValue placeholder="SEK" />
                                  </SelectTrigger>
                                  <SelectContent>
                                    <SelectItem value="SEK">SEK</SelectItem>
                                    <SelectItem value="USD">USD</SelectItem>
                                    <SelectItem value="EUR">EUR</SelectItem>
                                  </SelectContent>
                                </Select>
                                {contractError && !currency && (
                                  <p className="text-red-500 text-xs pl-1">
                                    Please fill in Currency to generate PDF.
                                  </p>
                                )}
                              </span>
                            </div>
                          )}
                          <div className="flex justify-start mt-6">
                            <button
                              className="bg-violet-500 text-white py-2 px-4 rounded-lg"
                              onClick={() => {
                                if (
                                  contractStartDate &&
                                  (type === "Up-sell" ||
                                    (type !== "Up-sell" &&
                                      contractTerm &&
                                      noticePeriod &&
                                      paymentTerm &&
                                      currency))
                                ) {
                                  setContractError(false);
                                  generateContractPDF({
                                    type: type,
                                    clientName: account.name,
                                    billingAddress: account.billingAddress,
                                    startDate: contractStartDate.split("T")[0],
                                    contractTerm: contractTerm,
                                    noticePeriod: noticePeriod,
                                    autoRenewal: autoRenewal,
                                    paymentTerm: type === "Customer Order Form" ? account.partner?.paymentTerm : paymentTerm,
                                    contractCurrency: currency,
                                    billingFrequency: billingFrequency,
                                    products: products,
                                    partnerName: account.partner?.partnerName,
                                    partnerAddress:
                                      account.partner?.billingAddress,
                                    previousContractStartDate:
                                      selectedOpportunity?.previousContract
                                        ?.contractStartDate
                                        ? DateTime.fromISO(
                                            selectedOpportunity.previousContract
                                              ?.contractStartDate
                                          ).toISODate()
                                        : null,
                                  });
                                } else {
                                  setContractError(true);
                                }
                              }}
                            >
                              Generate PDF
                            </button>
                          </div>
                        </div>
                      </TabPanel>
                    </TabPanels>
                  </TabGroup>
                  <div className="flex gap-4">
                    {!isAddingProducts && (
                      <>
                        <div className="flex self-start">
                          <button
                            className={`relative flex mt-4 w-[13.5rem] h-10 text-white rounded-lg items-center justify-center
                              ${
                                stage === "Closed Won" ||
                                stage === "Closed Lost"
                                  ? "bg-neutral-400"
                                  : "bg-violet-500"
                              }  
                            `}
                            disabled={
                              saveLoading ||
                              stage === "Closed Won" ||
                              stage === "Closed Lost"
                            }
                            onClick={handleUpdateOpportunity}
                          >
                            {/* Spinner positioned absolutely */}
                            {saveLoading && (
                              <div className="absolute left-2 flex items-center justify-center">
                                <svg
                                  className="animate-spin h-5 w-5 text-white"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                  ></circle>
                                  <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                  ></path>
                                </svg>
                              </div>
                            )}

                            {/* Centered button text */}
                            <div className="flex items-center justify-center">
                              {selectedOpportunity
                                ? "Update Opportunity"
                                : "Add Opportunity"}
                            </div>
                          </button>
                        </div>

                        <DialogClose
                          className="mt-4 bg-gray-400 text-white px-4 py-2 rounded-lg h-10"
                          onClick={closeSheet}
                        >
                          Close
                        </DialogClose>
                      </>
                    )}
                  </div>
                </div>
              </SheetHeader>
            </>
          )}
        </SheetContent>
      </Sheet>
    </>
  );
};

export default OpportunityDetails;
