interface ConversionRate {
  conversionRateId: number;
  startDate: string;
  endDate: string | null;
  currency: string;
  conversionRate: number;
  created: string;
}

// Utility function to add a timeout to an async operation
export const withTimeout = <T>(promise: Promise<T>, timeoutMs: number): Promise<T> => {
  return Promise.race([
    promise,
    new Promise<never>((_, reject) =>
      setTimeout(() => reject(new Error("Timeout")), timeoutMs)
    ),
  ]);
};

export const formatNumberWithThousandsSeparator = (
  number: number | string | null | undefined,
  suffix: string = "",
  decimals: number = 0,
  percentage: boolean = false
): string => {
  // Convert string to number if applicable
  if (typeof number === "string") {
    number = parseFloat(number);
  }

  // Handle invalid or missing values
  if (number === null || number === undefined || isNaN(number)) {
    return "-";
  }

  // Handle zero values
  if (number === 0) {
    return "0" + (suffix ? " " + suffix : "");
  }

  // Format with thousands separator
  let formattedNumber = number
    .toFixed(decimals)
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ");

  // Append percentage or suffix
  if (percentage) {
    return formattedNumber + "%";
  } else {
    return formattedNumber + (suffix ? " " + suffix : "");
  }
};


export const findCurrentConversionRate = (
  closeDate: string,
  currency: string,
  conversionRates: ConversionRate[]
) => {
  const closeDateObj = new Date(closeDate);

  const filteredRates = conversionRates.filter(
    (rate) =>
      rate.currency === currency &&
      new Date(rate.startDate) <= closeDateObj &&
      (!rate.endDate || new Date(rate.endDate) >= closeDateObj)
  );

  if (filteredRates.length === 0) {
    return null; // No matching conversion rate found
  }

  // Sort to get the latest by startDate and return the conversionRate
  filteredRates.sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime());

  return filteredRates[0].conversionRate;
};

export function truncateString(str: string, maxLength: number) {
  if (!str) {
    return str
  }

  if (str.length <= maxLength) {
    return str;
  }
  
  return str.slice(0, maxLength) + '...';
}


