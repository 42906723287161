import React, { useState, useEffect, Fragment } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  getAccount,
  getContacts,
  fetchOpportunities,
  updateAccount,
  getUsers,
  deleteOpportunity,
  createTrialCustomer,
  prolongTrial,
  createNote,
  getNotes,
  updateNote,
  getContracts,
} from "../../app.service";
import {
  BtnBold,
  BtnItalic,
  BtnUnderline,
  BtnClearFormatting,
  Editor,
  EditorProvider,
  Toolbar,
  BtnRedo,
  BtnUndo,
  HtmlButton,
  BtnLink,
  BtnBulletList,
  BtnNumberedList,
} from "react-simple-wysiwyg";

import "App.css";

import { useAppContext } from "../../AppContext";
import { DateTime } from "luxon";

import OpportunityDetails from "../Opportunities/OpportunityDetail";

import { formatNumberWithThousandsSeparator, truncateString } from "../methods";
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
  SelectValue,
} from "../ui/select";
import {
  Combobox,
  Dialog,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
  Transition,
} from "@headlessui/react";
import ContactDetails from "../Contacts/ContactDetail";
import { Label } from "recharts";
import { notificationType } from "../notification.type";
import ContractDetails from "../Contracts/ContractDetails";

interface User {
  userId: number;
  userName: string;
  userRole: string;
}

interface Country {
  country: string;
  territory: string;
}

interface Note {
  noteId: number | null;
  subject: string;
  type: string;
  description: string;
  noteDate: string;
  accountId: string;
  createdBy: number | null;
}

interface Partner {
  partnerId: string;
  partnerName: string;
  billingAddress: any;
}

const AccountDetail: React.FC = () => {
  const { currentToken, currentUser, countries, partners } = useAppContext();
  const { accountId } = useParams<{ accountId: string }>();
  const [accountDetails, setAccountDetails] = useState<any>(null);
  const [contacts, setContacts] = useState<any[]>([]);
  const [opportunities, setOpportunities] = useState<any[]>([]);
  const [selectedOpportunity, setSelectedOpportunity] = useState<any | null>(
    null
  );
  const [opportunityModalOpen, setOpportunityModalOpen] =
    useState<boolean>(false);
  const [selectedContact, setSelectedContact] = useState<any | null>(null);
  const [contactModalOpen, setContactModalOpen] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [users, setUsers] = useState<User[]>([]);
  const [query, setQuery] = useState<string>("");
  const [trialModalOpen, setTrialModalOpen] = useState<boolean>(false);
  const [noteModalOpen, setNoteModalOpen] = useState<boolean>(false);
  const [notes, setNotes] = useState<Note[]>([]);
  const [currentNote, setCurrentNote] = useState<Note>({
    noteId: null,
    subject: `Note ${DateTime.utc().toISODate()}`,
    description: "",
    type: "note",
    noteDate: DateTime.utc().toISO(),
    accountId: accountId || "",
    createdBy: null,
  });
  const [selectedTrialContact, setSelectedTrialContact] = useState<any | null>(
    ""
  );
  const [billingAddress, setBillingAddress] = useState<any>({
    line1: "",
    line2: "",
    postalCode: "",
    city: "",
    state: "",
    country: "",
  });

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [contracts, setContracts] = useState<any[]>([]);
  const [filteredContracts, setFilteredContracts] = useState<any[]>([]);
  const [hasContracts, setHasContracts] = useState<boolean>(false);
  const [contractSearchText, setContractSearchText] = useState<string>("");
  const [contractStatusFilter, setContractStatusFilter] =
    useState<string>("All");
  const [contractEndDateFilter, setContractEndDateFilter] =
    useState<string>("All");

  const [selectedContract, setSelectedContract] = useState<any | null>(null);
  const [contractModalOpen, setContractModalOpen] = useState<boolean>(false);

  const sections = ["Contacts", "Opportunities", "Notes"];

  if (hasContracts) {
    sections.push("Contracts");
    sections.push("Statistics");
  }

  const navigate = useNavigate();

  const fetchDetails = async () => {
    if (currentToken && accountId) {
      // Fetch account details
      const accountResult = await getAccount(currentToken, accountId);
      if (accountResult.success) {
        setAccountDetails(accountResult.data);
        setBillingAddress(accountResult.data.billingAddress);
        setSelectedTrialContact(
          contacts.find(
            (contact) =>
              contact.contactId === accountResult.data.primaryContactId
          )
        );
      }

      // Fetch contacts
      const contactsResult = await getContacts(currentToken, accountId);
      if (contactsResult.success) {
        setContacts(contactsResult.data);
      }

      // Fetch opportunities
      const opportunitiesResult = await fetchOpportunities(
        currentToken,
        accountId
      );
      if (opportunitiesResult.success) {
        setOpportunities(opportunitiesResult.data);
      }

      // Fetch users
      const usersResult = await getUsers(currentToken);
      if (usersResult.success) {
        setUsers(usersResult.data);
      }

      const notesResult = await getNotes(currentToken, accountId);
      if (notesResult.success) {
        setNotes(notesResult.data);
      }

      const contractsResult = await getContracts(currentToken);
      if (contractsResult.success && Array.isArray(contractsResult.data)) {
        const contracts = contractsResult.data.filter(
          (contract: { accountId: any }) => contract.accountId === accountId
        );

        setContracts(contracts);
        setFilteredContracts(contracts);
      }
    }
  };

  const handleAccountUpdate = async (account: any) => {
    if (currentToken && accountId) {
      const updateAccountResult = await postAccount(account);
      if (updateAccountResult.success) {
        setIsEditing(false);
        notificationType.success("Account updated!");
        fetchDetails();
      } else {
        notificationType.error(
          "Error updating account:",
          updateAccountResult.message
        );
      }
    }
  };

  const postAccount = async (account: any) => {
    if (currentToken && accountId) {
      const updateAccountResult = await updateAccount(
        accountId,
        account,
        currentToken
      );
      return updateAccountResult;
    }
  };

  useEffect(() => {
    fetchDetails();
  }, [currentToken, accountId]);

  useEffect(() => {
    if (contracts.length > 0) {
      setHasContracts(true);
    } else {
      setHasContracts(false);
    }
  }, [contracts]);

  useEffect(() => {
    // Apply filters whenever search text or filters change
    let filtered = [...contracts];

    if (contractSearchText) {
      filtered = filtered.filter((contract) =>
        contract.contractName
          .toLowerCase()
          .includes(contractSearchText.toLowerCase())
      );
    }

    if (contractStatusFilter !== "All") {
      filtered = filtered.filter(
        (contract) => contract.contractStatus === contractStatusFilter
      );
    }

    if (contractEndDateFilter !== "All") {
      const now = DateTime.now();
      filtered = filtered.filter((contract) => {
        const endDate = DateTime.fromISO(contract.contractEndDate);
        if (contractEndDateFilter === "This Year") {
          return endDate.year === now.year;
        } else if (contractEndDateFilter === "Next 6 Months") {
          return endDate.diff(now, "months").months <= 6 && endDate > now;
        }
        return true;
      });
    }

    setFilteredContracts(filtered);
  }, [
    contractSearchText,
    contractStatusFilter,
    contractEndDateFilter,
    contracts,
  ]);

  const handleOpportunityClick = (opportunity: any) => {
    setSelectedOpportunity(opportunity);
    setOpportunityModalOpen(true);
  };

  const clearSelectedOpportunity = () => {
    setSelectedOpportunity(null);
    fetchDetails();
    setOpportunityModalOpen(false);
  };

  function handleContactClick(contact: any): void {
    setSelectedContact(contact);
    setContactModalOpen(true);
  }

  const clearSelectedContact = () => {
    setSelectedContact(null);
    fetchDetails();
    setContactModalOpen(false);
  };

  function handleContractClick(contract: any): void {
    setSelectedContract(contract);
    setContractModalOpen(true);
  }

  const clearSelectedContract = () => {
    setSelectedContract(null);
    fetchDetails();
    setContractModalOpen(false);
  };

  const filteredUsers =
    query === ""
      ? users
      : users.filter((user) =>
          user.userName.toLowerCase().includes(query.toLowerCase())
        );

  const handleDeleteOpportunity = async (opportunityId: number) => {
    if (currentToken) {
      const deletedOpp = await deleteOpportunity(opportunityId, currentToken);
      if (deletedOpp.success) {
        fetchDetails();
      }
    }
  };

  async function handleTrialClick() {
    if (
      accountDetails.billingAddress?.country &&
      accountDetails.primaryContactId
    ) {
      setSelectedTrialContact(
        contacts.find(
          (contact) => contact.contactId === accountDetails.primaryContactId
        )
      );
      setTrialModalOpen(true);
    } else {
      notificationType.error(
        "Missing fields!",
        `
          Ensure the following is set to continue: 
          ${!accountDetails.billingAddress?.country ? "Country " : ""}
          ${!accountDetails.primaryContactId ? "Primary Contact " : ""}
        `
      );
    }
  }

  async function createTrial() {
    const trialObj = {
      customerName: accountDetails.name,
      invoicingEmail: selectedTrialContact.email,
      accountId: accountDetails.accountId,
      trial: true,
      trialEnds: DateTime.now().plus({ days: 30 }).toISODate(),
      billingAddress: billingAddress,
      users: [
        {
          userName: `${selectedTrialContact.firstName} ${selectedTrialContact.lastName}`,
          email: selectedTrialContact.email,
        },
      ],
    };

    if (currentToken) {
      const result = await createTrialCustomer(trialObj, currentToken);

      if (result.success) {
        const newAccountDetails = {
          ...accountDetails,
        };
        newAccountDetails.customerId = result.customerId;

        setAccountDetails(newAccountDetails);
        postAccount(newAccountDetails);
        setBillingAddress({
          line1: "",
          line2: "",
          postalCode: "",
          city: "",
          state: "",
          country: "",
        });
        setSelectedTrialContact("");
        setTrialModalOpen(false);
        fetchDetails();
      } else {
        notificationType.error("Error!", result.message);
      }
    }
  }

  async function prolongExistingTrial() {
    if (currentToken) {
      const result = await prolongTrial(
        currentToken,
        accountDetails.customerId,
        DateTime.utc().plus({ days: 30 }).toISODate()
      );
      if (result.success) {
        setTrialModalOpen(false);
        fetchDetails();
      }
    }
  }

  function handleAddNoteClick(note: Note | null) {
    if (note) {
      setCurrentNote(note);
    }
    setNoteModalOpen(true);
  }

  async function postNote(currentNote: Note) {
    if (currentToken) {
      if (currentNote.noteId) {
        const result = await updateNote(
          currentNote,
          currentNote.noteId,
          currentToken
        );
        if (result.success) {
          setNoteModalOpen(false);
          fetchDetails();
        }
      } else {
        const result = await createNote(currentNote, currentToken);

        if (result.success) {
          setCurrentNote({
            noteId: null,
            subject: `Note ${DateTime.utc().toISODate()}`,
            description: "",
            noteDate: DateTime.utc().toISO(),
            type: "note",
            accountId: accountDetails.accountId,
            createdBy: null,
          });

          setNoteModalOpen(false);
          fetchDetails();
        }
      }
    }
  }

  function setPrimaryContact(contact: any): void {
    if (accountDetails.primaryContactId === contact.contactId) {
      // Clear primary contact
      const updatedAccount = {
        ...accountDetails,
        primaryContactId: null,
      };
      setAccountDetails(updatedAccount);
      handleAccountUpdate(updatedAccount);
    } else {
      const updatedAccount = {
        ...accountDetails,
        primaryContactId: contact.contactId,
      };
      setAccountDetails(updatedAccount);
      handleAccountUpdate(updatedAccount);
    }
  }

  return (
    <div className="flex flex-col h-screen p-4">
      {accountDetails && (
        <div className="flex flex-col space-y-4 h-full">
          <div className="flex justify-between items-center">
            <div className="flex  items-center">
              <div className="flex items-center justify-center size-24 border rounded-lg mr-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-16 stroke-violet-500"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Z"
                  />
                </svg>
              </div>
              <span className="text-2xl font-bold items-center">
                {accountDetails.name}
              </span>
            </div>
          </div>
          <div className="flex h-full space-x-4">
            <div className="rounded-lg border w-1/2">
              <div className="w-full p-4">
                <div className="flex w-full text-sm justify-end gap-4">
                  {accountDetails.type !== "Customer" && (
                    <button
                      className={`text-white px-4 py-2 h-8 rounded-lg bg-violet-500`}
                      onClick={() => {
                        handleTrialClick();
                      }}
                    >
                      {accountDetails.customer?.trial === 1
                        ? "Prolong Trial"
                        : "Create Trial"}
                    </button>
                  )}

                  <button
                    className={`text-white px-4 py-2 h-8 rounded-lg ${
                      !isEditing
                        ? currentUser.userRole !== "Admin" &&
                          currentUser.userId !== accountDetails.ownerId
                          ? "bg-neutral-400"
                          : "bg-violet-500"
                        : "bg-violet-500"
                    }`}
                    disabled={
                      !isEditing
                        ? currentUser.userRole !== "Admin" &&
                          currentUser.userId !== accountDetails.ownerId
                        : false
                    }
                    onClick={() => {
                      if (isEditing) {
                        handleAccountUpdate(accountDetails);
                      }
                      setIsEditing(!isEditing);
                    }}
                  >
                    {isEditing ? "Save" : "Edit"}
                  </button>
                </div>
                <h3 className="flex pl-4 font-bold">Details</h3>
                <div className="grid grid-cols-2 gap-8 p-4 flex flex-col w-full">
                  <div>
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-slate-700">
                        Name
                      </label>
                      {isEditing ? (
                        <input
                          className="mt-1 block w-full p-2 h-10 border-gray-300 rounded-md border focus:ring-blue-500 focus:border-blue-500"
                          value={accountDetails.name}
                          onChange={(e) =>
                            setAccountDetails({
                              ...accountDetails,
                              name: e.target.value,
                            })
                          }
                        />
                      ) : (
                        <p className="mt-1 block w-full border border-transparent rounded-md p-2 bg-slate-100 h-10">
                          {accountDetails.name}
                        </p>
                      )}
                    </div>
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-slate-700">
                        Type
                      </label>
                      {isEditing && accountDetails.type === "Prospect" ? (
                        <Select
                          value={accountDetails.type || undefined}
                          onValueChange={(value) =>
                            setAccountDetails({
                              ...accountDetails,
                              type: value,
                            })
                          }
                        >
                          <SelectTrigger className="mt-1 w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500">
                            <SelectValue placeholder="Update type" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="Prospect">Prospect</SelectItem>
                            <SelectItem value="Partner">Partner</SelectItem>
                          </SelectContent>
                        </Select>
                      ) : (
                        <p className="mt-1 block w-full border border-transparent rounded-md p-2 bg-slate-100 h-10">
                          {accountDetails.type}
                        </p>
                      )}
                    </div>
                    {(currentUser.userRole === "Admin" ||
                      currentUser.userRole === "Partner Manager") && (
                      <div className="mb-4">
                        <label className="block text-sm font-medium text-slate-700">
                          Partner
                        </label>
                        {isEditing ? (
                          <Select
                            value={accountDetails.partnerId || undefined}
                            onValueChange={(value) =>
                              setAccountDetails({
                                ...accountDetails,
                                partnerId: value,
                              })
                            }
                          >
                            <SelectTrigger className="mt-1 w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500">
                              <SelectValue placeholder="Select partner.." />
                            </SelectTrigger>
                            <SelectContent>
                              {partners.map((partner: Partner) => {
                                return (
                                  <SelectItem value={partner.partnerId}>
                                    {partner.partnerName}
                                  </SelectItem>
                                );
                              })}
                            </SelectContent>
                          </Select>
                        ) : (
                          <p className="mt-1 block w-full border border-transparent rounded-md p-2 bg-slate-100 h-10">
                            {partners.find((p: { partnerId: any; }) => p.partnerId === accountDetails.partnerId)?.partnerName || ""}
                          </p>
                        )}
                      </div>
                    )}

                    <div className="mb-4">
                      <label className="block text-sm font-medium text-slate-700">
                        Status
                      </label>
                      {isEditing && accountDetails.type === "Prospect" ? (
                        <Select
                          value={accountDetails.status || undefined}
                          onValueChange={(value) =>
                            setAccountDetails({
                              ...accountDetails,
                              status: value,
                            })
                          }
                        >
                          <SelectTrigger className="mt-1 w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500">
                            <SelectValue placeholder="Update status" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="Not Contacted">
                              Not Contacted
                            </SelectItem>
                            <SelectItem value="Contacted">Contacted</SelectItem>
                            <SelectItem value="Disqualified">
                              Disqualified
                            </SelectItem>
                            <SelectItem
                              disabled={true}
                              value="Ongoing Opportunity"
                            >
                              Ongoing Opportunity
                            </SelectItem>
                          </SelectContent>
                        </Select>
                      ) : (
                        <p className="mt-1 block w-full border border-transparent rounded-md p-2 bg-slate-100 h-10">
                          {accountDetails.status}
                        </p>
                      )}
                    </div>
                    {accountDetails.accountStatus === "Prospect" && (
                      <div className="mb-4">
                        <label className="block text-sm font-medium text-slate-700">
                          Trial
                        </label>
                        <p className="mt-1 block w-full border border-transparent rounded-md p-2 bg-slate-100 h-10">
                          {accountDetails.customer?.trial === 1 ? "Yes" : "No"}
                        </p>
                      </div>
                    )}
                  </div>
                  <div>
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-slate-700">
                        Territory
                      </label>
                      {isEditing ? (
                        <Select
                          value={accountDetails.territory || undefined}
                          onValueChange={(value) =>
                            setAccountDetails({
                              ...accountDetails,
                              territory: value,
                            })
                          }
                        >
                          <SelectTrigger className="mt-1 w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500">
                            <SelectValue placeholder="Update territory" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="North America">
                              North America
                            </SelectItem>
                            <SelectItem value="EMEA">EMEA</SelectItem>
                            <SelectItem value="APAC">APAC</SelectItem>
                            <SelectItem value="LATAM">LATAM</SelectItem>
                          </SelectContent>
                        </Select>
                      ) : (
                        <p className="mt-1 block w-full border border-transparent rounded-md p-2 bg-slate-100 h-10">
                          {accountDetails.territory}
                        </p>
                      )}
                    </div>
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-slate-700">
                        Owner
                      </label>
                      <div className="flex items-center gap-2">
                        {isEditing ? (
                          <div className="flex w-full">
                            <Combobox
                              value={accountDetails.ownerId || undefined}
                              onChange={(value: number) =>
                                setAccountDetails({
                                  ...accountDetails,
                                  ownerId: value,
                                })
                              }
                            >
                              <div className="relative mt-1 w-full">
                                <div className="relative w-full cursor-default overflow-hidden rounded-lg border focus:outline-none sm:text-sm">
                                  <Combobox.Input
                                    className="w-full border-none focus:ring-0 p-2.5 text-gray-900"
                                    displayValue={(ownerId: number) =>
                                      users.find(
                                        (user) => user.userId === ownerId
                                      )?.userName || ""
                                    }
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => setQuery(e.target.value)}
                                  />
                                  <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                    <svg
                                      className="h-5 w-5 text-gray-400"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 20 20"
                                      stroke="currentColor"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M7 7l3-3m0 0l3 3m-3-3v12"
                                      />
                                    </svg>
                                  </Combobox.Button>
                                </div>
                                <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                  {filteredUsers.length === 0 ? (
                                    <div className="px-4 py-2 text-gray-700">
                                      No users found.
                                    </div>
                                  ) : (
                                    filteredUsers.map((user) => (
                                      <Combobox.Option
                                        key={user.userId}
                                        value={user.userId}
                                        className={({ active }) =>
                                          `relative cursor-default w-full select-none py-2 pl-10 pr-4 ${
                                            active
                                              ? "bg-blue-600 text-white"
                                              : "text-gray-900"
                                          }`
                                        }
                                      >
                                        {({ selected }) => (
                                          <>
                                            <span
                                              className={`block truncate ${
                                                selected
                                                  ? "font-medium"
                                                  : "font-normal"
                                              }`}
                                            >
                                              {user.userName}
                                            </span>
                                          </>
                                        )}
                                      </Combobox.Option>
                                    ))
                                  )}
                                </Combobox.Options>
                              </div>
                            </Combobox>
                          </div>
                        ) : (
                          <p className="mt-1 block w-full border border-transparent rounded-md p-2 bg-slate-100 h-10">
                            {users.find(
                              (u) => u.userId === accountDetails.ownerId
                            )?.userName || "Unkown"}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-slate-700">
                        Created
                      </label>
                      <p className="mt-1 block w-full border border-transparent rounded-md p-2 bg-slate-100 h-10">
                        {DateTime.fromISO(accountDetails.createdAt).toFormat(
                          "yyyy-MM-dd HH:mm"
                        )}
                      </p>
                    </div>
                    {accountDetails.accountStatus === "Prospect" && (
                      <div className="mb-4">
                        <label className="block text-sm font-medium text-slate-700">
                          Trial Ends
                        </label>
                        <p className="mt-1 block w-full border border-transparent rounded-md p-2 bg-slate-100 h-10">
                          {accountDetails?.customer?.trialEnds
                            ? DateTime.fromISO(
                                accountDetails.customer.trialEnds
                              ).toFormat("yyyy-MM-dd")
                            : "-"}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <h3 className="flex pl-4 font-bold">Billing Address</h3>
                <div className="grid grid-cols-2 gap-8 p-4 flex flex-col w-full">
                  <div>
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-slate-700">
                        Street
                      </label>
                      {isEditing ? (
                        <input
                          className="mt-1 block w-full p-2 h-10 border-gray-300 rounded-md border focus:ring-blue-500 focus:border-blue-500"
                          value={accountDetails.billingAddress?.line1}
                          onChange={(e) =>
                            setAccountDetails({
                              ...accountDetails,
                              billingAddress: {
                                ...accountDetails.billingAddress,
                                line1: e.target.value,
                              },
                            })
                          }
                        />
                      ) : (
                        <p className="mt-1 block w-full border border-transparent rounded-md p-2 bg-slate-100 h-10">
                          {accountDetails.billingAddress?.line1}
                        </p>
                      )}
                    </div>
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-slate-700">
                        Postal Code
                      </label>
                      {isEditing ? (
                        <input
                          className="mt-1 block w-full p-2 h-10 border-gray-300 rounded-md border focus:ring-blue-500 focus:border-blue-500"
                          value={accountDetails.billingAddress?.postal_code}
                          onChange={(e) =>
                            setAccountDetails({
                              ...accountDetails,
                              billingAddress: {
                                ...accountDetails.billingAddress,
                                postal_code: e.target.value,
                              },
                            })
                          }
                        />
                      ) : (
                        <p className="mt-1 block w-full border border-transparent rounded-md p-2 bg-slate-100 h-10">
                          {accountDetails.billingAddress?.postal_code}
                        </p>
                      )}
                    </div>{" "}
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-slate-700">
                        State
                      </label>
                      {isEditing ? (
                        <input
                          className="mt-1 block w-full p-2 h-10 border-gray-300 rounded-md border focus:ring-blue-500 focus:border-blue-500"
                          value={accountDetails.billingAddress?.state}
                          onChange={(e) =>
                            setAccountDetails({
                              ...accountDetails,
                              billingAddress: {
                                ...accountDetails.billingAddress,
                                state: e.target.value,
                              },
                            })
                          }
                        />
                      ) : (
                        <p className="mt-1 block w-full border border-transparent rounded-md p-2 bg-slate-100 h-10">
                          {accountDetails.billingAddress?.state}
                        </p>
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-slate-700">
                        Street 2
                      </label>
                      {isEditing ? (
                        <input
                          className="mt-1 block w-full p-2 h-10 border-gray-300 rounded-md border focus:ring-blue-500 focus:border-blue-500"
                          value={accountDetails.billingAddress?.line2}
                          onChange={(e) =>
                            setAccountDetails({
                              ...accountDetails,
                              billingAddress: {
                                ...accountDetails.billingAddress,
                                line2: e.target.value,
                              },
                            })
                          }
                        />
                      ) : (
                        <p className="mt-1 block w-full border border-transparent rounded-md p-2 bg-slate-100 h-10">
                          {accountDetails.billingAddress?.line2}
                        </p>
                      )}
                    </div>{" "}
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-slate-700">
                        City
                      </label>
                      {isEditing ? (
                        <input
                          className="mt-1 block w-full p-2 h-10 border-gray-300 rounded-md border focus:ring-blue-500 focus:border-blue-500"
                          value={accountDetails.billingAddress?.city}
                          onChange={(e) =>
                            setAccountDetails({
                              ...accountDetails,
                              billingAddress: {
                                ...accountDetails.billingAddress,
                                city: e.target.value,
                              },
                            })
                          }
                        />
                      ) : (
                        <p className="mt-1 block w-full border border-transparent rounded-md p-2 bg-slate-100 h-10">
                          {accountDetails.billingAddress?.city}
                        </p>
                      )}
                    </div>{" "}
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-slate-700">
                        Country
                      </label>
                      {isEditing ? (
                        <Select
                          value={
                            accountDetails.billingAddress?.country || undefined
                          }
                          onValueChange={(value) =>
                            setAccountDetails({
                              ...accountDetails,
                              billingAddress: {
                                ...accountDetails.billingAddress,
                                country: value,
                              },
                            })
                          }
                        >
                          <SelectTrigger className="mt-1 w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500">
                            <SelectValue placeholder="Select country.." />
                          </SelectTrigger>
                          <SelectContent>
                            {countries.map((country: Country) => {
                              return (
                                <SelectItem value={country.country}>
                                  {country.country}
                                </SelectItem>
                              );
                            })}
                          </SelectContent>
                        </Select>
                      ) : (
                        <p className="mt-1 block w-full border border-transparent rounded-md p-2 bg-slate-100 h-10">
                          {accountDetails.billingAddress?.country}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <TabGroup
              selectedIndex={selectedIndex}
              onChange={(index) => setSelectedIndex(index)}
              className={"flex flex-col w-1/2"}
            >
              <TabList className={"flex "}>
                {sections.map((label, index) => (
                  <Tab
                    key={label}
                    className={`flex flex-col border-x border-t px-4 py-1 w-32 p-4 items-center justify-center ${
                      selectedIndex === index
                        ? "border-t-violet-500 border-t-4"
                        : "bg-neutral-50"
                    }
                    ${index === sections.length - 1 ? "rounded-tr-lg" : ""}
                    first:rounded-tl-lg`}
                    onClick={() => setSelectedIndex(index)}
                  >
                    {label === "Contacts" && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                        />
                      </svg>
                    )}

                    {label === "Opportunities" && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                        />
                      </svg>
                    )}

                    {label === "Notes" && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                        />
                      </svg>
                    )}

                    {label === "Contracts" && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className={`size-5`}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                        />
                      </svg>
                    )}

                    {label === "Statistics" && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0 0 20.25 18V6A2.25 2.25 0 0 0 18 3.75H6A2.25 2.25 0 0 0 3.75 6v12A2.25 2.25 0 0 0 6 20.25Z"
                        />
                      </svg>
                    )}
                    {label}
                  </Tab>
                ))}
                <Tab disabled className={"flex flex-grow border-b"}></Tab>
              </TabList>
              <TabPanels
                className={
                  "flex w-full border-x border-b rounded-bl-lg rounded-br-lg p-4 h-full"
                }
              >
                <TabPanel className={"flex w-full"}>
                  <div className="w-full flex flex-col h-full">
                    <div className="flex justify-between pb-4">
                      <button
                        className={`text-white text-sm w-40 h-8 px-4 py-2 rounded-lg ${
                          !isEditing
                            ? currentUser.userRole !== "Admin" &&
                              currentUser.userId !== accountDetails.ownerId
                              ? "bg-neutral-400"
                              : "bg-violet-500"
                            : "bg-violet-500"
                        }`}
                        disabled={
                          !isEditing
                            ? currentUser.userRole !== "Admin" &&
                              currentUser.userId !== accountDetails.ownerId
                            : false
                        }
                        onClick={() => {
                          handleContactClick(null);
                        }}
                      >
                        Add Contact
                      </button>
                    </div>

                    {/* Header Row */}
                    <div className="flex justify-between px-4 py-2 bg-gray-100 text-sm font-semibold text-slate-700">
                      <span className="flex-[1] justify-start">Name</span>
                      <span className="flex-[1] justify-start">Title</span>
                      <span className="flex-[1] justify-start">Email</span>
                      <span className="flex-[1] justify-start">Phone</span>
                      <span className="flex justify-center w-32">
                        Primary Contact
                      </span>
                    </div>

                    <div className="h-[14rem] w-full overflow-y-auto">
                      {contacts.length > 0 ? (
                        <ul className="w-full">
                          {contacts.map((contact) => (
                            <li
                              key={contact.userId}
                              className="flex justify-between gap-2 items-center hover:bg-violet-50 py-1 px-4 rounded-lg"
                            >
                              <span
                                title={contact.name}
                                className="flex-[1] text-sm font-semibold text-slate-900 truncate justify-start"
                              >
                                {contact.name}
                              </span>
                              <span
                                title={contact.title}
                                className="flex-[1] text-sm text-slate-500 truncate justify-start"
                              >
                                {contact.title}
                              </span>
                              <span
                                title={contact.email}
                                className="flex-[1] text-sm text-slate-500 truncate justify-start"
                              >
                                <a
                                  href={`mailto:${contact.email}`}
                                  className="text-blue-500 underline justify-start"
                                >
                                  {contact.email}
                                </a>
                              </span>
                              <span
                                title={contact.phone}
                                className="flex-[1] text-sm text-slate-500 truncate justify-start"
                              >
                                <a
                                  href={`tel:${contact.phone}`}
                                  className="text-blue-500 underline justify-start"
                                >
                                  {contact.phone}
                                </a>
                              </span>
                              <div className="flex justify-center items-center w-32 gap-2">
                                <input
                                  type="radio"
                                  name="primaryContactId"
                                  checked={
                                    contact.contactId ===
                                    accountDetails.primaryContactId
                                  }
                                  onClick={() => setPrimaryContact(contact)}
                                />
                                {/* Edit Icon */}
                                <button
                                  onClick={() => handleContactClick(contact)}
                                  className="ml-2"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="size-5 stroke-violet-500"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p>No contacts available</p>
                      )}
                    </div>
                  </div>
                </TabPanel>

                <TabPanel className={"flex w-full"}>
                  <div className="flex h-full w-full rounded-lg flex flex-col">
                    {accountDetails.type !== "Customer" && (
                      <div className="flex justify-between pb-4">
                        <button
                          className={`text-white w-40 text-sm h-8 px-4 py-2 rounded-lg ${
                            !isEditing
                              ? currentUser.userRole !== "Admin" &&
                                currentUser.userId !== accountDetails.ownerId
                                ? "bg-neutral-400"
                                : "bg-violet-500"
                              : "bg-violet-500"
                          }`}
                          disabled={
                            !isEditing
                              ? currentUser.userRole !== "Admin" &&
                                currentUser.userId !== accountDetails.ownerId
                              : false
                          }
                          onClick={() => {
                            handleOpportunityClick(null);
                          }}
                        >
                          Add Opportunity
                        </button>
                      </div>
                    )}

                    <div className="w-full h-full overflow-y-auto">
                      {opportunities.length > 0 ? (
                        <div>
                          {opportunities.map((opportunity) => {
                            let date;

                            if (opportunity?.closeDate.includes("T")) {
                              date = DateTime.fromISO(opportunity?.closeDate);
                            } else {
                              date = DateTime.fromFormat(
                                opportunity?.closeDate,
                                "yyyy-MM-dd HH:mm:ss"
                              );
                            }

                            const monthDay = date.toFormat("LLL dd");
                            const year = date.toFormat("yyyy");

                            return (
                              <div
                                key={opportunity.opportunityId}
                                className="w-full border-b p-2 mb-2 cursor-pointer hover:bg-violet-50 rounded-lg flex gap-8 items-center h-24"
                              >
                                <div
                                  className="flex justify-between w-full h-full"
                                  onClick={() =>
                                    handleOpportunityClick(opportunity)
                                  }
                                >
                                  <div className="flex w-full items-center gap-4">
                                    <div className="flex flex-col font-medium bg-blue-100 text-slate-700 size-20 rounded-lg items-center justify-center">
                                      <div className="">{monthDay}</div>
                                      <div className="">{year}</div>
                                    </div>

                                    <div className="text-sm">
                                      <span className="flex">
                                        <p className="w-28 font-light">
                                          Name:{" "}
                                        </p>
                                        <p className="font-semibold text-blue-500">
                                          {opportunity.name}
                                        </p>
                                      </span>
                                      <span className="flex">
                                        <p className="w-28 font-light">Type:</p>
                                        <p className="font-semibold text-emerald-400">
                                          {opportunity.type}
                                        </p>
                                      </span>
                                      <span className="flex">
                                        <p className="w-28 font-light">
                                          Stage:
                                        </p>
                                        <p className="font-semibold text-violet-400">
                                          {opportunity.stage}
                                        </p>
                                      </span>
                                      <span className="flex">
                                        <p className="w-28 font-light">ARR:</p>
                                        <p className="font-semibold text-pink-400">
                                          {formatNumberWithThousandsSeparator(
                                            opportunity.annualRecurringRevenue,
                                            ` ${opportunity.currency}`
                                          )}
                                        </p>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                {currentUser.userRole === "Admin" && (
                                  <div className="items-center h-full flex z-10">
                                    <button
                                      className="bg-red-400 text-white p-2 rounded-full"
                                      onClick={() =>
                                        handleDeleteOpportunity(
                                          opportunity.opportunityId
                                        )
                                      }
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="size-5"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                        />
                                      </svg>
                                    </button>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <p>No opportunities available</p>
                      )}
                    </div>
                  </div>
                </TabPanel>
                <TabPanel className={"flex flex-col w-full"}>
                  <div className="flex justify-between pb-4">
                    <button
                      className={`text-white text-sm h-8 px-4 py-2 w-40 rounded-lg bg-violet-500`}
                      onClick={() => {
                        handleAddNoteClick(null);
                      }}
                    >
                      Add Note
                    </button>
                  </div>

                  <div className="h-[20rem] overflow-y-auto">
                    {notes.length > 0 ? (
                      <ul className="">
                        {notes.map((note) => (
                          <li
                            key={note.noteId}
                            className="mb-2 pb-2 flex justify-between items-center hover:bg-violet-50 rounded-lg py-1 px-2 border-b border-slate-200 last:border-b-0"
                            onClick={() => {
                              handleAddNoteClick(note);
                            }}
                          >
                            <span className="flex w-full items-center justify-between">
                              <div className="flex">
                                {note.type === "email" && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="size-5"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                                    />
                                  </svg>
                                )}
                                {note.type === "note" && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="size-5"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                                    />
                                  </svg>
                                )}
                                {note.type === "meeting" && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="size-5"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
                                    />
                                  </svg>
                                )}
                                {note.type === "call" && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="size-5"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                                    />
                                  </svg>
                                )}
                                <span className="px-4 text-sm font-semibold text-slate-900">
                                  {note.subject}
                                </span>
                              </div>

                              <div className="grid grid-flow-col w-[20rem] divide-x items-center">
                                <div>
                                  <span className="px-4 w-40 text-sm text-slate-500">
                                    {note.createdBy && users.length > 0
                                      ? users.find(
                                          (user) =>
                                            user.userId === note.createdBy
                                        )?.userName || ""
                                      : ""}
                                  </span>
                                  <span className="px-4 text-sm text-slate-500">
                                    {DateTime.fromISO(note.noteDate).toFormat(
                                      "yyyy-MM-dd HH:MM"
                                    )}
                                  </span>
                                </div>
                              </div>
                            </span>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p>No notes available</p>
                    )}
                  </div>
                </TabPanel>
                {contracts.length > 0 && (
                  <>
                    <TabPanel className={"flex w-full"}>
                      <div className="w-full flex flex-col h-full">
                        <div className="flex justify-between pb-4">
                          <input
                            type="text"
                            placeholder="Search Contracts..."
                            value={contractSearchText}
                            onChange={(e) =>
                              setContractSearchText(e.target.value)
                            }
                            className="border p-2 rounded-lg text-sm w-48"
                          />
                          <div className="flex gap-2">
                            <Select
                              value={contractStatusFilter || undefined}
                              onValueChange={setContractStatusFilter}
                            >
                              <SelectTrigger className="w-32">
                                <SelectValue placeholder="Status" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem disabled value="select">
                                  Status
                                </SelectItem>
                                <SelectItem value="All">
                                  All Statuses
                                </SelectItem>
                                <SelectItem value="Draft">Draft</SelectItem>
                                <SelectItem value="Active">Active</SelectItem>
                                <SelectItem value="Expired">Expired</SelectItem>
                                <SelectItem value="Terminated">
                                  Terminated
                                </SelectItem>
                              </SelectContent>
                            </Select>
                            <Select
                              value={contractEndDateFilter || undefined}
                              onValueChange={setContractEndDateFilter}
                            >
                              <SelectTrigger className="w-32">
                                <SelectValue placeholder="End Date" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem disabled value="select">
                                  End Date
                                </SelectItem>
                                <SelectItem value="All">All</SelectItem>
                                <SelectItem value="This Year">
                                  This Year
                                </SelectItem>
                                <SelectItem value="Next 6 Months">
                                  Next 6 Months
                                </SelectItem>
                              </SelectContent>
                            </Select>
                          </div>
                        </div>

                        {/* Header Row */}
                        <div className="flex justify-between px-4 py-2 bg-gray-100 text-sm font-semibold text-slate-700">
                          <span className="flex-[3] text-left">Name</span>{" "}
                          {/* Name gets double space */}
                          <span className="flex-[1] text-left">Start</span>
                          <span className="flex-[1] text-left">End</span>
                          <span className="flex-[1] text-left">Status</span>
                          <span className="flex-[1] text-left">Notice</span>
                          <span className="flex-[0.5] text-center">
                            {/* Empty for actions */}
                          </span>
                        </div>

                        {/* Content Rows */}
                        <div className="h-[14rem] w-full overflow-y-auto">
                          {filteredContracts.length > 0 ? (
                            <ul className="w-full">
                              {filteredContracts.map((contract) => (
                                <li
                                  key={contract.contractId}
                                  onClick={() => handleContractClick(contract)}
                                  className="flex justify-between gap-2 items-center hover:bg-violet-50 py-1 px-4 rounded-lg"
                                >
                                  <span
                                    title={contract.contractName}
                                    className="flex-[3] text-sm font-semibold text-slate-900 truncate pr-2"
                                  >
                                    {contract.contractName}
                                  </span>
                                  <span
                                    title={
                                      DateTime.fromISO(
                                        contract.contractStartDate
                                      ).toISODate() || ""
                                    }
                                    className="flex-[1] text-sm text-slate-500 truncate"
                                  >
                                    {DateTime.fromISO(
                                      contract.contractStartDate
                                    ).toISODate()}
                                  </span>
                                  <span
                                    title={
                                      DateTime.fromISO(
                                        contract.contractEndDate
                                      ).toISODate() || ""
                                    }
                                    className="flex-[1] text-sm text-slate-500 truncate"
                                  >
                                    {DateTime.fromISO(
                                      contract.contractEndDate
                                    ).toISODate()}
                                  </span>
                                  <span
                                    title={contract.contractStatus}
                                    className="flex-[1] text-sm text-slate-500 truncate"
                                  >
                                    {contract.contractStatus}
                                  </span>
                                  <span
                                    title={contract.noticePeriod + " days"}
                                    className="flex-[1] text-sm text-slate-500 truncate"
                                  >
                                    {contract.noticePeriod + " days"}
                                  </span>
                                  <div className="flex-[0.5] justify-center items-center gap-2"></div>
                                </li>
                              ))}
                            </ul>
                          ) : (
                            <p className="flex pl-1 pt-2">
                              No contracts available
                            </p>
                          )}
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel className={"flex flex-col w-full"}>
                      IN DEVELOPMENT 🚀
                    </TabPanel>
                  </>
                )}
              </TabPanels>
            </TabGroup>
          </div>

          <button
            className="bg-gray-300 text-black px-4 py-2 rounded-lg mb-4 self-start w-32"
            onClick={() => navigate("/accounts")}
          >
            Go back
          </button>

          {/* Opportunity Details Modal */}
          {opportunityModalOpen && (
            <OpportunityDetails
              selectedOpportunity={selectedOpportunity}
              account={accountDetails}
              clearSelectedOpportunity={clearSelectedOpportunity} // Pass the callback to clear the opportunity
            />
          )}

          {/* Opportunity Details Modal */}
          {contactModalOpen && (
            <ContactDetails
              selectedContact={selectedContact}
              account={accountDetails}
              clearSelectedContact={clearSelectedContact} // Pass the callback to clear the opportunity
            />
          )}

          {contractModalOpen && (
            <ContractDetails
              selectedContract={selectedContract}
              clearSelectedContract={clearSelectedContract} // Pass the callback to clear the opportunity
              accountName={accountDetails.name}
              accountId={accountDetails.accountId}
            />
          )}

          {/* Opportunity Details Modal */}
          {trialModalOpen && (
            <Transition show={trialModalOpen} as={Fragment}>
              <Dialog
                open={trialModalOpen}
                onClose={() => setTrialModalOpen(false)}
                className="relative z-[200]"
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div
                    className="fixed inset-0 bg-black/50 backdrop-blur-md"
                    aria-hidden="true"
                  />
                </Transition.Child>
                <div className="fixed inset-0 flex items-center justify-center md:p-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="mx-auto shadow-lg p-8 flex flex-col justify-between bg-white rounded-2xl w-[40%]">
                      {accountDetails.customer?.trial === 1 ? (
                        <div>
                          <span className="flex flex-col  w-full">
                            <h3 className="font-bold text-xl">Create Trial</h3>
                            {/* Trial Information */}
                            <div className="mt-4 text-sm">
                              <p>
                                The trial with current end{" "}
                                {DateTime.fromISO(
                                  accountDetails.customer.trialEnds
                                ).toFormat("yyyy-MM-dd")}{" "}
                                will be prolonged until{" "}
                                {DateTime.utc().plus({ days: 30 }).toISODate()}.
                              </p>
                            </div>
                          </span>
                          <div className="flex justify-end mt-6">
                            <button
                              className={`bg-blue-600 text-white px-4 py-2 rounded-md bg-violet-500`}
                              onClick={() => {
                                prolongExistingTrial();
                              }}
                            >
                              Prolong Trial
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <span className="flex flex-col gap-2 w-full">
                            <h3 className="font-bold text-xl">Create Trial</h3>
                            <div className="mt-4 text-sm">
                              <p>
                                An Enterprise Trial will be created for this
                                account with an end date of{" "}
                                {new Date(
                                  Date.now() + 30 * 24 * 60 * 60 * 1000
                                ).toLocaleDateString()}
                                .
                              </p>
                              <p>
                                An invite email will be sent to{" "}
                                {
                                  contacts.find(
                                    (contact) =>
                                      contact.contactId ===
                                      accountDetails.primaryContactId
                                  ).email
                                }
                                .
                              </p>
                            </div>
                          </span>
                          <div className="flex justify-end mt-6">
                            <button
                              className={`text-white px-4 py-2 rounded-md bg-violet-500`}
                              onClick={() => {
                                if (
                                  accountDetails.billingAddress?.country &&
                                  accountDetails.primaryContactId
                                ) {
                                  createTrial();
                                } else {
                                  notificationType.error(
                                    "Missing fields!",
                                    `
                                    Ensure the following is set to continue: 
                                    ${
                                      !accountDetails.billingAddress?.country
                                        ? "Country "
                                        : ""
                                    }
                                    ${
                                      !accountDetails.primaryContactId
                                        ? "Primary Contact "
                                        : ""
                                    }
                                  `
                                  );
                                }
                              }}
                            >
                              Create Trial
                            </button>
                          </div>
                        </div>
                      )}
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </Dialog>
            </Transition>
          )}

          {noteModalOpen && (
            <Transition show={noteModalOpen} as={Fragment}>
              <Dialog
                open={noteModalOpen}
                onClose={() => setNoteModalOpen(false)}
                className="relative z-[200]"
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div
                    className="fixed inset-0 bg-black/50 backdrop-blur-md"
                    aria-hidden="true"
                  />
                </Transition.Child>
                <div className="fixed inset-0 flex items-center justify-center md:p-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="mx-auto shadow-lg p-8 flex flex-col justify-between bg-white rounded-2xl w-[40%]">
                      <div className="flex justify-between mb-4">
                        <h3 className="font-bold text-xl">Add Note</h3>
                        <div className="flex items-center overflow-clip first: rounded-l-2xl last: rounded-r-2xl ">
                          <button
                            className="bg-blue-200 px-4 py-1"
                            onClick={(e) =>
                              setCurrentNote({
                                ...currentNote,
                                subject: `Sent Email ${DateTime.utc().toISODate()}`,
                                description: `<b>Sent To: </b>[Name of Contact]&nbsp;</span></div><div><span style="background-color: rgb(255 255 255 / var(--tw-bg-opacity)); color: hsl(var(--foreground));"><b>Subject:</b> [Email Subject]</span></div><div><span style="background-color: rgb(255 255 255 / var(--tw-bg-opacity)); color: hsl(var(--foreground));"><b>Email Summary:</b></span><span style="background-color: rgb(255 255 255 / var(--tw-bg-opacity)); color: hsl(var(--foreground));">&nbsp;[Brief summary of the content of the email]</span></div><div><span style="background-color: rgb(255 255 255 / var(--tw-bg-opacity)); color: hsl(var(--foreground));"><b>Response Received:</b> [Yes/No]</span></div><div><span style="background-color: rgb(255 255 255 / var(--tw-bg-opacity)); color: hsl(var(--foreground));"><b>Next Steps:</b> </span><span style="background-color: rgb(255 255 255 / var(--tw-bg-opacity)); color: hsl(var(--foreground));">[Any follow-up actions]`,
                                noteDate: DateTime.utc().toISO(),
                                type: "email",
                              })
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="size-5"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                              />
                            </svg>
                          </button>
                          <button
                            className="bg-amber-200 px-4 py-1"
                            onClick={(e) =>
                              setCurrentNote({
                                ...currentNote,
                                subject: `Meeting ${DateTime.utc().toISODate()}`,
                                description: `<b>Meeting/Interaction Type: </b><span style="background-color: rgb(255 255 255 / var(--tw-bg-opacity)); color: hsl(var(--foreground));">[In-Person/Virtual/Other]</span></div><div><b style="background-color: rgb(255 255 255 / var(--tw-bg-opacity)); color: hsl(var(--foreground));">Discussion Summary:</b><span style="background-color: rgb(255 255 255 / var(--tw-bg-opacity)); color: hsl(var(--foreground));">&nbsp;</span><span style="background-color: rgb(255 255 255 / var(--tw-bg-opacity)); color: hsl(var(--foreground));">[Summary of the conversation]</span></div><div><b>Key Takeaways:</b>&nbsp;<span style="background-color: rgb(255 255 255 / var(--tw-bg-opacity)); color: hsl(var(--foreground));">[Key takeaways or feedback]</span></div><div><b>Actions Agreed Upon:&nbsp;</b><span style="background-color: rgb(255 255 255 / var(--tw-bg-opacity)); color: hsl(var(--foreground));">[Any specific follow-up actions]</span></div><div><b>Deadline for Next Action: </b>[YYYY-MM-DD]`,
                                noteDate: DateTime.utc().toISO(),
                                type: "meeting",
                              })
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="size-5"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
                              />
                            </svg>
                          </button>
                          <button
                            className="bg-green-200 px-4 py-1"
                            onClick={(e) =>
                              setCurrentNote({
                                ...currentNote,
                                subject: `Call ${DateTime.utc().toISODate()}`,
                                description: `<b>Contact Person:</b> [Name]<div><b>Purpose of Call:</b> [Introduction/Follow-up/Closing/etc.]&nbsp;</div><div><b>Key Discussion Points:</b>&nbsp;[Main topic 1]
<span class="hljs-bullet">  -</span> [Main topic 2]&nbsp;</div><div><b>Customer Concerns:</b> [Any concerns they raised]</div><div><b>Next Steps:&nbsp;</b>[Agreed actions or follow-up]</div><div><b>Call</b> <b>Outcome:</b> [Positive/Negative/Neutral]</div>`,
                                noteDate: DateTime.utc().toISO(),
                                type: "call",
                              })
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="size-5"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>

                      <div className={``}>
                        <input
                          className="mt-1 block w-full p-2 h-10 border-gray-300 rounded-md border focus:ring-blue-500 focus:border-blue-500 mb-4"
                          value={currentNote.subject}
                          onChange={(e) =>
                            setCurrentNote({
                              ...currentNote,
                              subject: e.target.value,
                            })
                          }
                        />
                        <EditorProvider>
                          <Editor
                            value={currentNote.description}
                            containerProps={{
                              style: {
                                resize: "vertical",
                                fontSize: "13px",
                                borderRadius: "0.375rem",
                                height: "250px",
                              },
                            }}
                            onChange={(e) =>
                              setCurrentNote({
                                ...currentNote,
                                description: e.target.value,
                              })
                            }
                          >
                            <Toolbar>
                              <BtnRedo />
                              <BtnUndo />
                              <BtnBold />
                              <BtnItalic />
                              <BtnLink />
                              <BtnBulletList />
                              <BtnNumberedList />
                              <BtnUnderline />
                              <BtnClearFormatting />
                              <HtmlButton />
                            </Toolbar>
                          </Editor>
                        </EditorProvider>
                        <div className="flex gap-4 mt-4">
                          <button
                            className={`text-white p-1 rounded-2xl w-20 text-sm ${
                              currentNote.subject && currentNote.description
                                ? "bg-violet-500"
                                : "bg-neutral-400"
                            }`}
                            disabled={
                              !currentNote.subject || !currentNote.description
                            }
                            onClick={() => {
                              postNote(currentNote);
                            }}
                          >
                            {currentNote.noteId ? "Update" : "Save"}
                          </button>
                          <button
                            className="bg-white border border-black/50 text-black p-1 rounded-2xl w-20 text-sm"
                            onClick={() => {
                              setCurrentNote({
                                noteId: null,
                                subject: `Note ${DateTime.utc().toISODate()}`,
                                description: "",
                                noteDate: DateTime.utc().toISO(),
                                type: "note",
                                accountId: accountDetails.accountId,
                                createdBy: null,
                              });
                              setNoteModalOpen(false);
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </Dialog>
            </Transition>
          )}
        </div>
      )}
    </div>
  );
};

export default AccountDetail;
